import { Flex, Image, Text, Spinner } from "@chakra-ui/react";


export default function BackButton({ setAction, caption, lastScroll, setTabs }) {
    return (
        <Flex align="center" fontWeight="500">
            <Flex align="center" cursor="pointer" onClick={() => {
                setAction(0)
                if(lastScroll) {
                    window.scrollTo(0, lastScroll)
                }
                if(setTabs) {
                    setTabs(0)
                }
            }} _hover={{ textDecoration: "underline" }}>                
                <svg width="20" height="21" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.4" d="M6 11.5C8.76142 11.5 11 9.26142 11 6.5C11 3.73858 8.76142 1.5 6 1.5C3.23858 1.5 1 3.73858 1 6.5C1 9.26142 3.23858 11.5 6 11.5Z" fill="#6421F2"/>
                <path d="M6.62996 8.64008C6.53496 8.64008 6.43996 8.60508 6.36496 8.53008L4.59996 6.76508C4.45496 6.62008 4.45496 6.38008 4.59996 6.23508L6.36496 4.47008C6.50996 4.32508 6.74996 4.32508 6.89496 4.47008C7.03996 4.61508 7.03996 4.85508 6.89496 5.00008L5.39496 6.50008L6.89496 8.00008C7.03996 8.14508 7.03996 8.38508 6.89496 8.53008C6.82496 8.60508 6.72996 8.64008 6.62996 8.64008Z" fill="#6421F2"/>
                </svg>
                <Text ml="3" fontSize="16px" color="#6421F2">Back</Text>
            </Flex>
            <Text mx="6">
                <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_538_8172)">
                <path d="M3.67863 12.5C3.44839 12.4999 3.22332 12.4296 3.0319 12.2979C2.84047 12.1662 2.69128 11.9791 2.60317 11.7601C2.51507 11.5411 2.49201 11.3002 2.53692 11.0677C2.58182 10.8353 2.69267 10.6217 2.85546 10.4541L6.68957 6.50736L2.85546 2.56061C2.74425 2.45005 2.65555 2.3178 2.59453 2.17158C2.53351 2.02535 2.50139 1.86808 2.50004 1.70894C2.4987 1.5498 2.52816 1.39198 2.5867 1.24469C2.64525 1.09739 2.7317 0.963573 2.84102 0.85104C2.95034 0.738507 3.08034 0.649513 3.22343 0.58925C3.36653 0.528987 3.51984 0.498662 3.67444 0.500045C3.82904 0.501428 3.98182 0.534491 4.12387 0.597305C4.26593 0.660118 4.3944 0.751425 4.50181 0.865896L9.1591 5.66001C9.37738 5.88476 9.5 6.18956 9.5 6.50736C9.5 6.82517 9.37738 7.12997 9.1591 7.35472L4.50181 12.1488C4.28351 12.3736 3.9874 12.4999 3.67863 12.5Z" fill="#D0D5DD"/>
                </g>
                <defs>
                <clipPath id="clip0_538_8172">
                <rect width="12" height="12" fill="white" transform="translate(0 0.5)"/>
                </clipPath>
                </defs>
                </svg>
            </Text>
            <Text color="#98A2B3" fontSize="16px">{caption}</Text>
        </Flex>
    )
}