import Logo from "../assets/images/logo.svg"
import { Flex, Image, Text, Spinner } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import InputHolder from "../components/inputHolder";
import { useState, useRef } from "react";
import { AuthConnect, SignIn } from "../util/request";
import Toast from "../components/notify";

export default function SetUp() {
    const [pass, setPass] = useState(false)
    const [loading, setLoading] = useState(false)
    const history = useHistory()
    const ref1 = useRef("")
    const ref2 = useRef("")

    async function allowLogin() {
        var a = ref1.current.value
        var b = ref2.current.value
        if(a !== "" && b !== "") {
            setPass(true)
        }
        else {
            setPass(false)
        }
    }


    async function authUser(e) {
        e.preventDefault()
        if(pass) {
            setLoading(true)
            const res = await AuthConnect("post", "accounts/activation", {
                token: sessionStorage.getItem("ZineToken"),
                new_password: ref1.current.value,
            })
            sessionStorage.setItem("ZineToken", res?.access_token)
            sessionStorage.setItem("ZineRefreshToken", res?.refresh_token)
            if(res?.is_activated) {
                sessionStorage.setItem("ZineAuth", true)
                history.replace("/dashboard")
                Toast("Account Activation Successful", "success")
            }
            setLoading(false)
        }
    }

    return (
        <Flex w="100%" direction="column">
            <Flex w="100%" px="10%" py="60px" mb="50px">
                <Image src={Logo} w="157.88px" />
            </Flex>

            <Flex flex="1" justify="center" pb="50px" data-aos="fade-up">
                <Flex w="620px" direction="column">
                    <Text fontSize="32px" lineHeight="48px" fontWeight="700">Complete Your Sign Up</Text>
                    <Text fontSize="16px" fontWeight="500" color="#637381" mt="2">Welcome Admin! Please enter a new password for your account.</Text>

                    <form onSubmit={(e) => authUser(e)} style={{ width: "100%" }}>
                        <Flex w="100%" direction="column" mt="10">
                            <InputHolder type="password" placeholder="Password" inpRef={ref1} onInput={() => allowLogin()} required />
                        </Flex>

                        <Flex mt="7" w="100%">
                            <Flex as="button" transition="200ms ease-in-out" w="100%" padding="10px 18px" bg={pass ? "#6421F2" : "#E9D7FE"} color="#fff" justify="center" align="center" fontWeight="500" borderRadius="8px" boxShadow="0px 1px 2px 0px #1018280D" cursor={pass ? "pointer" : "not-allowed"}>{loading ? <Spinner color="#fff" emptyColor="lightgrey" /> : "Continue"}</Flex>
                        </Flex>
                    </form>
                </Flex>
            </Flex>
        </Flex>
    )
}