import { Flex, Image, Text, Spinner } from "@chakra-ui/react";
import { AuthConnect } from "../../util/request";
import { errorIcon } from "../svgs";
import { useState, useEffect, useRef } from "react";





export default function LowBal({ modal, setModal }) {
    return (
        <Flex w="100%" direction="column">
            <Flex w="100%" direction="column" align="center">
                {errorIcon}
                <Text textAlign="center" mt="5" fontSize="24px" color="#212B36" fontWeight="600">{modal?.data[0]}</Text>
                <Text mt="2" px="5" textAlign="center" fontWeight="500" color="#637381" fontSize="16px">{modal?.data[1]}</Text>
            </Flex>

            <Flex w="100%" justify="space-between" mt="6" mb="2">
                <Flex cursor="pointer" bg="#6421F2" border="1px solid #6421F2" color="#fff" borderRadius="8px" padding="10px 18px" w="100%" justify="center" align="center" fontWeight="500" onClick={() => setModal({
                    isOpen: false,
                })}>Okay</Flex>
            </Flex>
        </Flex>
    )
}