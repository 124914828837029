export const crypt_arr = [
    {"fullname": "Bitcoin", "abbreviation": "BTC"},
    {"fullname": "Ethereum", "abbreviation": "ETH"},
    {"fullname": "Binance Coin", "abbreviation": "BNB"},
    {"fullname": "Tether", "abbreviation": "USDT"},
    {"fullname": "Solana", "abbreviation": "SOL"},
    {"fullname": "Cardano", "abbreviation": "ADA"},
    {"fullname": "XRP", "abbreviation": "XRP"},
    {"fullname": "Polkadot", "abbreviation": "DOT"},
    {"fullname": "USD Coin", "abbreviation": "USDC"},
    {"fullname": "Dogecoin", "abbreviation": "DOGE"},
    {"fullname": "Avalanche", "abbreviation": "AVAX"},
    {"fullname": "Shiba Inu", "abbreviation": "SHIB"},
    {"fullname": "Chainlink", "abbreviation": "LINK"},
    {"fullname": "Litecoin", "abbreviation": "LTC"},
    {"fullname": "Algorand", "abbreviation": "ALGO"},
    {"fullname": "Bitcoin Cash", "abbreviation": "BCH"},
    {"fullname": "Ethereum Classic", "abbreviation": "ETC"},
    {"fullname": "Polygon", "abbreviation": "MATIC"},
    {"fullname": "Wrapped Bitcoin", "abbreviation": "WBTC"},
    {"fullname": "Filecoin", "abbreviation": "FIL"},
    {"fullname": "Theta Network", "abbreviation": "THETA"},
    {"fullname": "TRON", "abbreviation": "TRX"},
    {"fullname": "Stellar", "abbreviation": "XLM"},
    {"fullname": "Bitcoin SV", "abbreviation": "BSV"},
    {"fullname": "Tezos", "abbreviation": "XTZ"},
    {"fullname": "FTX Token", "abbreviation": "FTT"},
    {"fullname": "EOS", "abbreviation": "EOS"},
    {"fullname": "Internet Computer", "abbreviation": "ICP"},
    {"fullname": "Cosmos", "abbreviation": "ATOM"},
    {"fullname": "VeChain", "abbreviation": "VET"},
    {"fullname": "Crypto.com Coin", "abbreviation": "CRO"},
    {"fullname": "Aave", "abbreviation": "AAVE"},
    {"fullname": "THORChain", "abbreviation": "RUNE"},
    {"fullname": "UNUS SED LEO", "abbreviation": "LEO"},
    {"fullname": "Compound", "abbreviation": "COMP"},
    {"fullname": "PancakeSwap", "abbreviation": "CAKE"},
    {"fullname": "Fantom", "abbreviation": "FTM"},
    {"fullname": "Kusama", "abbreviation": "KSM"},
    {"fullname": "Monero", "abbreviation": "XMR"},
    {"fullname": "Bitcoin BEP2", "abbreviation": "BTCB"},
    {"fullname": "Axie Infinity", "abbreviation": "AXS"},
    {"fullname": "NEAR Protocol", "abbreviation": "NEAR"},
    {"fullname": "Flow", "abbreviation": "FLOW"},
    {"fullname": "The Sandbox", "abbreviation": "SAND"},
    {"fullname": "Huobi Token", "abbreviation": "HT"},
    {"fullname": "Amp", "abbreviation": "AMP"},
    {"fullname": "Decentraland", "abbreviation": "MANA"},
    {"fullname": "Maker", "abbreviation": "MKR"},
    {"fullname": "Quant", "abbreviation": "QNT"},
    {"fullname": "Harmony", "abbreviation": "ONE"},
    {"fullname": "Zcash", "abbreviation": "ZEC"},
    {"fullname": "Helium", "abbreviation": "HNT"},
    {"fullname": "Dai", "abbreviation": "DAI"},
    {"fullname": "Chiliz", "abbreviation": "CHZ"},
    {"fullname": "Dash", "abbreviation": "DASH"},
    {"fullname": "SushiSwap", "abbreviation": "SUSHI"},
    {"fullname": "Enjin Coin", "abbreviation": "ENJ"},
    {"fullname": "Horizen", "abbreviation": "ZEN"},
    {"fullname": "The Graph", "abbreviation": "GRT"},
    {"fullname": "Nexo", "abbreviation": "NEXO"},
    {"fullname": "Terra", "abbreviation": "LUNA"},
    {"fullname": "Klaytn", "abbreviation": "KLAY"},
    {"fullname": "Curve DAO Token", "abbreviation": "CRV"},
    {"fullname": "Hedera Hashgraph", "abbreviation": "HBAR"},
    {"fullname": "Theta Fuel", "abbreviation": "TFUEL"},
    {"fullname": "OKB", "abbreviation": "OKB"},
    {"fullname": "Waves", "abbreviation": "WAVES"},
    {"fullname": "Ontology", "abbreviation": "ONT"},
    {"fullname": "TrueUSD", "abbreviation": "TUSD"},
    {"fullname": "yearn.finance", "abbreviation": "YFI"},
    {"fullname": "Siacoin", "abbreviation": "SC"},
    {"fullname": "BitTorrent", "abbreviation": "BTT"},
    {"fullname": "Revain", "abbreviation": "REV"},
    {"fullname": "Zilliqa", "abbreviation": "ZIL"},
    {"fullname": "Telcoin", "abbreviation": "TEL"},
    {"fullname": "Celsius", "abbreviation": "CEL"},
    {"fullname": "Compound USD Coin", "abbreviation": "CUSDC"},
    {"fullname": "Ren", "abbreviation": "REN"},
    {"fullname": "Bitcoin Gold", "abbreviation": "BTG"},
    {"fullname": "0x", "abbreviation": "ZRX"},
    {"fullname": "Arweave", "abbreviation": "AR"},
    {"fullname": "Bitcoin Cash ABC", "abbreviation": "BCHA"},
    {"fullname": "NEM", "abbreviation": "XEM"},
    {"fullname": "Qtum", "abbreviation": "QTUM"},
    {"fullname": "Paxos Standard", "abbreviation": "PAX"},
    {"fullname": "WazirX", "abbreviation": "WRX"},
    {"fullname": "Basic Attention Token", "abbreviation": "BAT"},
    {"fullname": "IOST", "abbreviation": "IOST"},
    {"fullname": "OMG Network", "abbreviation": "OMG"},
    {"fullname": "Gala", "abbreviation": "GALA"},
    {"fullname": "Synthetix", "abbreviation": "SNX"},
    {"fullname": "Fetch.ai", "abbreviation": "FET"},
    {"fullname": "Nano", "abbreviation": "NANO"},
    {"fullname": "Voyager Token", "abbreviation": "VGX"},
    {"fullname": "Celo", "abbreviation": "CELO"},
    {"fullname": "Holo", "abbreviation": "HOT"},
    {"fullname": "Elrond", "abbreviation": "EGLD"},
    {"fullname": "RenBTC", "abbreviation": "RENBTC"},
    {"fullname": "Ankr", "abbreviation": "ANKR"},
    {"fullname": "KuCoin Token", "abbreviation": "KCS"},
    {"fullname": "Bitcoin Diamond", "abbreviation": "BCD"},
    {"fullname": "SushiSwap (SUSHI)", "abbreviation": "SUSHI"},
    {"fullname": "Bitcoin Cash SV", "abbreviation": "BCHSV"},
    {"fullname": "Nervos Network", "abbreviation": "CKB"},
    {"fullname": "Harmony (ONE)", "abbreviation": "ONE"},
    {"fullname": "Verge", "abbreviation": "XVG"},
    {"fullname": "Curve DAO Token (CRV)", "abbreviation": "CRV"},
    {"fullname": "Venus", "abbreviation": "XVS"},
    {"fullname": "TomoChain", "abbreviation": "TOMO"},
    {"fullname": "Wink", "abbreviation": "WIN"},
    {"fullname": "Raydium", "abbreviation": "RAY"},
    {"fullname": "DigiByte", "abbreviation": "DGB"},
    {"fullname": "HUSD", "abbreviation": "HUSD"},
    {"fullname": "Reserve Rights", "abbreviation": "RSR"},
    {"fullname": "Bitcoin Private", "abbreviation": "BTCP"},
    {"fullname": "Orion Protocol", "abbreviation": "ORN"},
    {"fullname": "FEG Token", "abbreviation": "FEG"},
    {"fullname": "Mina", "abbreviation": "MINA"},
    {"fullname": "SwissBorg", "abbreviation": "CHSB"},
    {"fullname": "UMA", "abbreviation": "UMA"},
    {"fullname": "Balancer", "abbreviation": "BAL"},
    {"fullname": "Hive", "abbreviation": "HIVE"},
    {"fullname": "Civic", "abbreviation": "CVC"},
    {"fullname": "Ardor", "abbreviation": "ARDR"},
    {"fullname": "Mirror Protocol", "abbreviation": "MIR"},
    {"fullname": "Ocean Protocol", "abbreviation": "OCEAN"},
    {"fullname": "Energy Web Token", "abbreviation": "EWT"},
    {"fullname": "Stacks", "abbreviation": "STX"},
    {"fullname": "Dent", "abbreviation": "DENT"},
    {"fullname": "Lisk", "abbreviation": "LSK"},
    {"fullname": "DODO", "abbreviation": "DODO"},
    {"fullname": "Serum", "abbreviation": "SRM"},
    {"fullname": "NKN", "abbreviation": "NKN"},
    {"fullname": "Storj", "abbreviation": "STORJ"},
    {"fullname": "Aragon", "abbreviation": "ANT"},
    {"fullname": "Livepeer", "abbreviation": "LPT"},
    {"fullname": "Horizen (ZEN)", "abbreviation": "ZEN"},
    {"fullname": "Augur", "abbreviation": "REP"},
    {"fullname": "Ampleforth", "abbreviation": "AMPL"},
    {"fullname": "Keep Network", "abbreviation": "KEEP"},
    {"fullname": "Balancer (BAL)", "abbreviation": "BAL"},
    {"fullname": "SafeMoon", "abbreviation": "SAFEMOON"},
    {"fullname": "Alpha Finance Lab", "abbreviation": "ALPHA"},
    {"fullname": "MediBloc", "abbreviation": "MED"},
    {"fullname": "The Transfer Token", "abbreviation": "TTT"},
    {"fullname": "Saffron Finance", "abbreviation": "SFI"},
    {"fullname": "district0x", "abbreviation": "DNT"},
    {"fullname": "Hifi Finance", "abbreviation": "MFT"},
    {"fullname": "SuperFarm", "abbreviation": "SUPER"},
    {"fullname": "Stratis", "abbreviation": "STRAX"},
    {"fullname": "SOLVE", "abbreviation": "SOLVE"},
    {"fullname": "Cream Finance", "abbreviation": "CREAM"},
    {"fullname": "Akash Network", "abbreviation": "AKT"},
    {"fullname": "Nervos Network (CKB)", "abbreviation": "CKB"},
    {"fullname": "IRISnet", "abbreviation": "IRIS"},
    {"fullname": "Trust Wallet Token", "abbreviation": "TWT"},
    {"fullname": "SKALE Network", "abbreviation": "SKL"},
    {"fullname": "NXM", "abbreviation": "NXM"},
    {"fullname": "RedFOX Labs", "abbreviation": "RFOX"},
    {"fullname": "Kava", "abbreviation": "KAVA"},
    {"fullname": "NuCypher", "abbreviation": "NU"},
    {"fullname": "IRISnet (IRIS)", "abbreviation": "IRIS"},
    {"fullname": "Sora", "abbreviation": "XOR"},
    {"fullname": "Alchemix", "abbreviation": "ALCX"},
    {"fullname": "Frontier", "abbreviation": "FRONT"},
    {"fullname": "Perpetual Protocol", "abbreviation": "PERP"},
    {"fullname": "Pundi X", "abbreviation": "PUNDIX"},
    {"fullname": "StakeHound Staked Ether", "abbreviation": "STETH"},
    {"fullname": "Cream Finance (CREAM)", "abbreviation": "CREAM"},
    {"fullname": "sUSD", "abbreviation": "SUSD"},
    {"fullname": "Naira", "abbreviation": "NGN"},
]