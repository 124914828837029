import { Flex, Image, Text } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";

export default function Header({ title, sub, type, setModal, loadData, roles }) {
    return (
        <Flex w="100%" mt="2" justify="space-between" align="center">
            <Flex direction="column">
                <Text color="#212B36" fontWeight="700" fontSize="32px" lineHeight="48px">{title}</Text>
                <Text color="#637381" fontWeight="500" fontSize="16px" lineHeight="24px" mt="1">{sub}</Text>
            </Flex>
            
           {type === "admin" && <Flex cursor="pointer" bg="#6421F2" color="#fff" ml="auto" borderRadius="8px" padding="10px 18px" w="197px" justify="center" align="center" fontWeight="500" onClick={() => setModal({
                isOpen: true,
                id: "CREATE_USER",
                data: roles,
                reload: loadData,
            })
            }>Add new</Flex>}
        </Flex>
    )
}