import { Flex, Image, Text, Spinner } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { AuthConnect } from "../../util/request";
import { useEffect, useState } from "react";
import Header from "../header";
import Status from "../status";
import Toast from "../notify";
import { showDate, showTime } from "../svgs";


export default function TransactionsModal({ setIsOpen, data, loadData }) {
    const [loading, setLoading] = useState(false)
    const [loading2, setLoading2] = useState(false)
    const [mData, setMdata] = useState(false)
    const [isPending, setIsPending] = useState(false)

    

    useEffect(async () => {
        setLoading(true)
        const res = await AuthConnect("get", "/customer-transactions/"+data?.id+"?expand[fields]=creditor_id,credited_holding_id,creditor_wallet_id,linked_exchange_order_id")
        setMdata(res)
        setLoading(false)
    }, [])

    useEffect(() => {
        if(mData) {
            if(["SUCCESS", "Success", "success"].includes(mData?.data?.status)) {
                setIsPending(false)
            }
            else {
                setIsPending(true)
            }
        }
    }, [mData])

    async function updateTransaction() {
        const res = await AuthConnect("get", "/customer-transactions/"+data?.id+"?expand[fields]=creditor_id,credited_holding_id,creditor_wallet_id,linked_exchange_order_id")
        if(res) {
            setMdata(res)
        }
    }


    async function verifyTransaction() {
        setLoading2(true)
        const res = await AuthConnect("post", "/customer-transactions/"+data?.id+"/verification-request", {
            id: data?.id
        })
        if(res) {
            await updateTransaction()
            await loadData(null, null, true)
            Toast(res?.message, "success")
        }
        setLoading2(false)
    }

    const mArr = mData?.expansions?.linked_exchange_order?.length > 0 ? 
    [
        {name: "Transaction ID:", val: mData?.data?.id},
        {name: "Transaction type:", val: mData?.data?.type},
        {name: "Transaction Status:", val: <Status status={mData?.data?.status} />},
        {name: "Amount:", val: mData?.data?.currency+" "+parseFloat(mData?.data?.amount)?.toLocaleString()},
        {name: mData?.data?.type[0].toUpperCase() + mData?.data?.type.slice(1).toLowerCase()+" Type:", val: mData?.expansions?.linked_exchange_order[0]?.type},
        {name:  mData?.data?.type[0].toUpperCase() + mData?.data?.type.slice(1).toLowerCase()+" Status:", val: <Status status={mData?.expansions?.linked_exchange_order[0]?.status} />},
        {name: "Base:", val: mData?.expansions?.linked_exchange_order[0]?.base},
        {name: "Recieved:", val: parseFloat(mData?.expansions?.linked_exchange_order[0]?.receive)+" "+mData?.expansions?.linked_exchange_order[0]?.base},
        {name: "Rate:", val: parseFloat(mData?.expansions?.linked_exchange_order[0]?.rate)?.toLocaleString()},
        {name: "Fees:", val: parseFloat(mData?.expansions?.linked_exchange_order[0]?.fee)+" "+mData?.expansions?.linked_exchange_order[0]?.base},
        {name: "Quote:", val: mData?.expansions?.linked_exchange_order[0]?.quote},
        {name: "Processor Fees:", val:  mData?.data?.currency+" "+parseFloat(mData?.data?.fees?.processor_fees)?.toLocaleString()},
        {name: "Zine Fees:", val:  mData?.data?.currency+" "+parseFloat(mData?.data?.fees?.zine_fees)?.toLocaleString()},
        {name: "Creditor:", val: mData?.expansions?.creditor[0]?.profile?.fullname ?? "___"},
        {name: "Balance:", val: parseFloat(mData?.expansions?.credited_holding[0]?.balance)?.toLocaleString()+" "+mData?.expansions?.credited_holding[0]?.currency},
        {name: "Currency Type:", val: mData?.expansions?.credited_holding[0]?.currency_type},
        {name: "Funding Balance:", val: parseFloat(mData?.expansions?.credited_holding[0]?.funding_balance)?.toLocaleString()+" "+mData?.expansions?.credited_holding[0]?.currency},
        {name: "Spot Balance:", val: parseFloat(mData?.expansions?.credited_holding[0]?.spot_balance)?.toLocaleString()+" "+mData?.expansions?.credited_holding[0]?.currency},
        {name: "Wallet ID:", val: mData?.data?.creditor_wallet_id},
        {name: "Date created:", val: showDate(mData?.expansions?.linked_exchange_order[0]?.created_at?.substr(0,10)) + " " + showTime(mData?.expansions?.linked_exchange_order[0]?.created_at)},
        {name: "Processor:", val: mData?.data?.processor},
    ]
    :
    [
        {name: "Transaction ID:", val: mData?.data?.id},
        {name: "Transaction type:", val: mData?.data?.type},
        {name: "Transaction Status:", val: <Status status={mData?.data?.status} />},
        {name: "Amount:", val: mData?.data?.currency+" "+parseFloat(mData?.data?.amount)?.toLocaleString()},
        {name: "Processor Fees:", val:  mData?.data?.currency+" "+parseFloat(mData?.data?.fees?.processor_fees)?.toLocaleString()},
        {name: "Zine Fees:", val:  mData?.data?.currency+" "+parseFloat(mData?.data?.fees?.zine_fees)?.toLocaleString()},
        {name: "Creditor:", val: mData?.expansions?.creditor[0]?.profile?.fullname ?? "___"},
        {name: "Balance:", val: parseFloat(mData?.expansions?.credited_holding[0]?.balance)?.toLocaleString()+" "+mData?.expansions?.credited_holding[0]?.currency},
        {name: "Currency Type:", val: mData?.expansions?.credited_holding[0]?.currency_type},
        {name: "Funding Balance:", val: parseFloat(mData?.expansions?.credited_holding[0]?.funding_balance)?.toLocaleString()+" "+mData?.expansions?.credited_holding[0]?.currency},
        {name: "Spot Balance:", val: parseFloat(mData?.expansions?.credited_holding[0]?.spot_balance)?.toLocaleString()+" "+mData?.expansions?.credited_holding[0]?.currency},
        {name: "Wallet ID:", val: mData?.data?.creditor_wallet_id},
        {name: "Date created:", val: showDate(mData?.data?.created_at?.substr(0,10)) + " " + showTime(mData?.data?.created_at)},
        {name: "Processor:", val: mData?.data?.processor},
    ]

    return (
        <Flex w="100%" bg="rgba(22, 28, 36, .8)" position="fixed" top="0" left="0" h="100%" justify="flex-end" zIndex="1000">
            <Flex bg="#fff" padding="40px 20px 40px 40px" w="598px" minH="100vh" boxShadow="-40px 40px 80px -8px #0000003D" direction='column' data-aos="fade-left">
                <Header title="Transaction Details" sub="View transaction details" />
                <Flex flex="1" direction="column" mt="8" overflowY="scroll" className="customScroll" pr="20px">
                    {
                        loading ?
                        <Flex w="100%" justify="center" py="100px">
                            <Spinner color="#6421F2" emptyColor="lightgrey" w="50px" h="50px" />
                        </Flex>
                        :
                        mData ?
                        mArr.map((item, index) => (
                            <Flex w="100%" key={index} pb="8px" pt="10px" borderBottom="1px solid #919EAB33" justify="space-between" align="flex-start">
                                <Text fontSize="16px" color="#212B36" fontWeight="700" mr="4" textTransform="capitalize">{item.name}</Text>
                                <Text maxW="65%" justify="flex-end" wordBreak="break-all" fontWeight="500" color="#637381" fontSize="16px">{item.val}</Text>
                            </Flex>
                        ))
                        :
                        <Flex w="100%" justify="center" py="100px" color="#637381" fontWeight="500">No Data</Flex>
                    }
                </Flex>
                <Flex w="100%" mt="auto">
                    {
                        isPending && <Flex mt="5" mr="12" cursor="pointer" bg="#6421F2" color="#fff" ml="auto" borderRadius="8px" padding="10px 18px" w="100%" justify="center" align="center" fontWeight="500" onClick={() => verifyTransaction()}>{loading2 ? <>Verifying <Spinner emptyColor="lightgrey" color="#fff" ml="2" /></> : "Verify Transaction"}</Flex>
                    }
                    <Flex mt="5" cursor="pointer" bg="#6421F2" color="#fff" ml="auto" borderRadius="8px" padding="10px 18px" w="100%" justify="center" align="center" fontWeight="500" onClick={() => setIsOpen(false)}>Close</Flex>
                </Flex>
            </Flex>
        </Flex>
    )
}