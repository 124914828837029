import { Flex, Image, Text, Spinner } from "@chakra-ui/react";
import { AuthConnect } from "../../util/request";
import { errorIcon } from "../svgs";
import { useState, useEffect, useRef } from "react";





export default function Delete({ modal, setModal }) {
    const [loading, setLoading] = useState(false)

    async function modalFunc() {
        setLoading(true)
        const res = await AuthConnect("delete", "/customers/"+modal?.data)
        if(res) {
            await modal.func()
            modal.refresh(0)
            setModal({ isOpen: false })
            setModal({
                isOpen: true,
                id: "SUCCESS",
                data: ["Deleted", "You have successfully soft deleted this user"]
            })
        }
        setLoading(false)
    }

    return (
        <Flex w="100%" direction="column">
            <Flex w="100%" direction="column" align="center">
                {errorIcon}
                <Text textAlign="center" mt="5" fontSize="24px" color="#212B36" fontWeight="600">Delete User</Text>
                <Text mt="2" px="5" textAlign="center" fontWeight="500" color="#637381" fontSize="16px">This user will be soft deleted and account will be suspended.</Text>
            </Flex>

            <Flex w="100%" justify="space-between" mt="8">
                <Flex cursor="pointer" bg="#fff" border="1px solid #D0D5DD" color="#212B36" borderRadius="8px" padding="10px 18px" w="48.5%" justify="center" align="center" fontWeight="500" boxShadow="0px 1px 2px 0px #1018280D" onClick={() => setModal({
                    isOpen: false,
                })}>Cancel</Flex>

                <Flex cursor="pointer" bg="#6421F2" border="1px solid #6421F2" color="#fff" borderRadius="8px" padding="10px 18px" w="48.5%" justify="center" align="center" fontWeight="500" onClick={() => modalFunc()}>{loading ? <Spinner color="#fff" emptyColor="lightgrey" /> : "Delete"}</Flex>
            </Flex>
        </Flex>
    )
}