import { Flex, Image, Text } from "@chakra-ui/react";
import { useState } from "react";

export default function InputHolder({ type, inpRef, options, empty, label, noMb, loadData, searchFunc, isSearch, setIsSearch, ...props }) {
    const [show, setShow] = useState(false)
    return (
        <Flex w="100%" fontSize="16px" mb={noMb ? "0" : "6"} direction="column">
            {
                label && <Text color="#344054" fontSize="14px" fontWeight="500" mb="2">{label}</Text>
            }
            {
                type === "password" ?
                <Flex w="100%" position="relative" align="center">
                    <input ref={inpRef} style={{ width: "100%", padding: "10px 44px 10px 14px", border: "1px solid #D0D5DD", boxShadow: "0px 1px 2px 0px #1018280D", borderRadius: "8px" }} type={show ? "text" : "password"} {...props} />

                    <Text position="absolute" py="2px" px="2px" cursor="pointer" right="12px" fontSize="20px" color="#667085" onClick={() => show ? setShow(false) : setShow(true)}><i className={show ? "mdi mdi-eye-outline" : "mdi mdi-eye-off-outline"}></i></Text>
                </Flex>
                :
                type === "search" ?
                <Flex w="100%" position="relative" align="center">
                    <input ref={inpRef} style={{ width: "100%", padding: "10px 14px 10px 44px", border: "1px solid #D0D5DD", boxShadow: "0px 1px 2px 0px #1018280D", borderRadius: "8px" }} type={type} {...props} />

                    <Text position="absolute" py="2px" px="2px" cursor="pointer" left="12px" fontSize="20px" color="#667085" onClick={() => {
                        if(isSearch) {
                            setIsSearch(false)
                            inpRef.current.value = ""
                            loadData()
                        }
                        else if(inpRef.current.value !== "") {
                            setIsSearch(true)
                            searchFunc()
                        }
                    }}>{
                        isSearch ?
                        <Text fontSize="18px" color="#667085"><i className="mdi mdi-close"></i></Text>
                        :
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.5 16.5L12.875 12.875M14.8333 8.16667C14.8333 11.8486 11.8486 14.8333 8.16667 14.8333C4.48477 14.8333 1.5 11.8486 1.5 8.16667C1.5 4.48477 4.48477 1.5 8.16667 1.5C11.8486 1.5 14.8333 4.48477 14.8333 8.16667Z" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/></svg>
                    }</Text>
                </Flex>
                :
                type === "select" ?
                <select ref={inpRef} style={{ width: "100%", padding: "10px 14px", border: "1px solid #D0D5DD", boxShadow: "0px 1px 2px 0px #1018280D", borderRadius: "8px", color: "#667085", minWidth: "200px" }} {...props}>
                    <option value="">{empty}</option>
                    {
                        options.map((item, index) => (
                            <option key={index}>{item}</option>
                        ))
                    }
                </select>
                :
                <input ref={inpRef} style={{ width: "100%", padding: "10px 14px", border: "1px solid #D0D5DD", boxShadow: "0px 1px 2px 0px #1018280D", borderRadius: "8px" }} type={type} {...props} />
            }
        </Flex>
    )
}