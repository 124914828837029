import { Flex, Image, Text } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import Logo from "../assets/images/logo.svg"

export default function MenuBar() {
    const history = useHistory()
    const [select, setSelect] = useState(1)

    const menus = [{name: "Dashboard", icon: icon1, link: "/dashboard/metrics"}, {name: "Users", icon: icon2, link: "/dashboard/users"}, {name: "KYC Verification", icon: icon3, link: "/dashboard/kyc"}, {name: "KYC Jobs", icon: icon5, link: "/dashboard/jobs"}, {name: "Transactions", icon: icon4, link: "/dashboard/transactions"}, {name: "Assets", icon: icon5, link: "/dashboard/assets"}, {name: "Wallets", icon: icon6, link: "/dashboard/wallets"}, {name: "Finance Ops", icon: icon7, link: "/dashboard/finance-ops"}, {name: "Audit Trail", icon: icon8, link: "/dashboard/audit-trail"}, {name: "Admin", icon: icon9, link: "/dashboard/admin"}, {name: "Enquiry", icon: icon10, link: "/dashboard/enquiry"}]

    useEffect(() => {
        history.location.pathname.includes("kyc") ? setSelect(2) : history.location.pathname.includes("jobs") ? setSelect(3) : history.location.pathname.includes("metrics") ? setSelect(0) : history.location.pathname.includes("transactions") ? setSelect(4) : history.location.pathname.includes("assets") ? setSelect(5) : history.location.pathname.includes("wallets") ? setSelect(6) : history.location.pathname.includes("finance-ops") ? setSelect(7) : history.location.pathname.includes("audit-trail") ? setSelect(8) : history.location.pathname.includes("admin") ? setSelect(9) : history.location.pathname.includes("enquiry") ? setSelect(10) : setSelect(1)
    }, [history.location.pathname])

    return (
        <Flex w="100%" direction="column" h="100%">
            <Flex w="100%" px="16px"><Image src={Logo} w="93.73px" /></Flex>
            <Flex mt="12" direction="column" w="100%">
                {
                    menus.map((item, index) => (
                        <>
                            <Flex mb="2" w="100%" key={index} borderRadius="8px" px="12px" py="12px" bg={select === index ? "#6421F214" : "transparent"} transition="200ms ease-in-out" align="center" color={select === index ? "#6421F2" : "currentColor"} fontWeight={select === index ? "600" : "500"} cursor="pointer" _hover={{ color: "#6421F2", bg: "#6421F214", fontWeight: "600" }} onClick={() => {
                                history.push(item.link)
                            }}>
                                {item.icon}
                                <Text ml="16px" fontSize="14px">{item.name}</Text>
                            </Flex>
                        </>
                    ))
                }
            </Flex>
        </Flex>
    )
}


const icon1 = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.24 2H5.34C3.15 2 2 3.15 2 5.33V7.23C2 9.41 3.15 10.56 5.33 10.56H7.23C9.41 10.56 10.56 9.41 10.56 7.23V5.33C10.57 3.15 9.42 2 7.24 2Z" fill="currentColor"/>
<path opacity="0.4" d="M18.6699 2H16.7699C14.5899 2 13.4399 3.15 13.4399 5.33V7.23C13.4399 9.41 14.5899 10.56 16.7699 10.56H18.6699C20.8499 10.56 21.9999 9.41 21.9999 7.23V5.33C21.9999 3.15 20.8499 2 18.6699 2Z" fill="currentColor"/>
<path d="M18.6699 13.4302H16.7699C14.5899 13.4302 13.4399 14.5802 13.4399 16.7602V18.6602C13.4399 20.8402 14.5899 21.9902 16.7699 21.9902H18.6699C20.8499 21.9902 21.9999 20.8402 21.9999 18.6602V16.7602C21.9999 14.5802 20.8499 13.4302 18.6699 13.4302Z" fill="currentColor"/>
<path opacity="0.4" d="M7.24 13.4302H5.34C3.15 13.4302 2 14.5802 2 16.7602V18.6602C2 20.8502 3.15 22.0002 5.33 22.0002H7.23C9.41 22.0002 10.56 20.8502 10.56 18.6702V16.7702C10.57 14.5802 9.42 13.4302 7.24 13.4302Z" fill="currentColor"/>
</svg>



const icon2 = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.4" d="M9 2C6.38 2 4.25 4.13 4.25 6.75C4.25 9.32 6.26 11.4 8.88 11.49C8.96 11.48 9.04 11.48 9.1 11.49C9.12 11.49 9.13 11.49 9.15 11.49C9.16 11.49 9.16 11.49 9.17 11.49C11.73 11.4 13.74 9.32 13.75 6.75C13.75 4.13 11.62 2 9 2Z" fill="currentColor"/>
<path d="M14.08 14.1499C11.29 12.2899 6.73996 12.2899 3.92996 14.1499C2.65996 14.9999 1.95996 16.1499 1.95996 17.3799C1.95996 18.6099 2.65996 19.7499 3.91996 20.5899C5.31996 21.5299 7.15996 21.9999 8.99996 21.9999C10.84 21.9999 12.68 21.5299 14.08 20.5899C15.34 19.7399 16.04 18.5999 16.04 17.3599C16.03 16.1299 15.34 14.9899 14.08 14.1499Z" fill="currentColor"/>
<path opacity="0.4" d="M19.9901 7.3401C20.1501 9.2801 18.7701 10.9801 16.8601 11.2101C16.8501 11.2101 16.8501 11.2101 16.8401 11.2101H16.8101C16.7501 11.2101 16.6901 11.2101 16.6401 11.2301C15.6701 11.2801 14.7801 10.9701 14.1101 10.4001C15.1401 9.4801 15.7301 8.1001 15.6101 6.6001C15.5401 5.7901 15.2601 5.0501 14.8401 4.4201C15.2201 4.2301 15.6601 4.1101 16.1101 4.0701C18.0701 3.9001 19.8201 5.3601 19.9901 7.3401Z" fill="currentColor"/>
<path d="M21.99 16.5899C21.91 17.5599 21.29 18.3999 20.25 18.9699C19.25 19.5199 17.99 19.7799 16.74 19.7499C17.46 19.0999 17.88 18.2899 17.96 17.4299C18.06 16.1899 17.47 14.9999 16.29 14.0499C15.62 13.5199 14.84 13.0999 13.99 12.7899C16.2 12.1499 18.98 12.5799 20.69 13.9599C21.61 14.6999 22.08 15.6299 21.99 16.5899Z" fill="currentColor"/>
</svg>


const icon3 = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.4" d="M10.7499 2.45007C11.4399 1.86007 12.5699 1.86007 13.2699 2.45007L14.8499 3.81007C15.1499 4.07007 15.7099 4.28007 16.1099 4.28007H17.8099C18.8699 4.28007 19.7399 5.15007 19.7399 6.21007V7.91007C19.7399 8.30007 19.9499 8.87007 20.2099 9.17007L21.5699 10.7501C22.1599 11.4401 22.1599 12.5701 21.5699 13.2701L20.2099 14.8501C19.9499 15.1501 19.7399 15.7101 19.7399 16.1101V17.8101C19.7399 18.8701 18.8699 19.7401 17.8099 19.7401H16.1099C15.7199 19.7401 15.1499 19.9501 14.8499 20.2101L13.2699 21.5701C12.5799 22.1601 11.4499 22.1601 10.7499 21.5701L9.16988 20.2101C8.86988 19.9501 8.30988 19.7401 7.90988 19.7401H6.17988C5.11988 19.7401 4.24988 18.8701 4.24988 17.8101V16.1001C4.24988 15.7101 4.03988 15.1501 3.78988 14.8501L2.43988 13.2601C1.85988 12.5701 1.85988 11.4501 2.43988 10.7601L3.78988 9.17007C4.03988 8.87007 4.24988 8.31007 4.24988 7.92007V6.20007C4.24988 5.14007 5.11988 4.27007 6.17988 4.27007H7.90988C8.29988 4.27007 8.86988 4.06007 9.16988 3.80007L10.7499 2.45007Z" fill="currentColor"/>
<path d="M10.7901 15.17C10.5901 15.17 10.4001 15.09 10.2601 14.95L7.84006 12.53C7.55006 12.24 7.55006 11.76 7.84006 11.47C8.13006 11.18 8.61006 11.18 8.90006 11.47L10.7901 13.36L15.0901 9.06003C15.3801 8.77003 15.8601 8.77003 16.1501 9.06003C16.4401 9.35003 16.4401 9.83003 16.1501 10.12L11.3201 14.95C11.1801 15.09 10.9901 15.17 10.7901 15.17Z" fill="currentColor"/>
</svg>




const icon4 = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22 22.75H5C2.93 22.75 1.25 21.07 1.25 19V2C1.25 1.59 1.59 1.25 2 1.25C2.41 1.25 2.75 1.59 2.75 2V19C2.75 20.24 3.76 21.25 5 21.25H22C22.41 21.25 22.75 21.59 22.75 22C22.75 22.41 22.41 22.75 22 22.75Z" fill="currentColor"/>
<path opacity="0.4" d="M5.00007 17.7501C4.83007 17.7501 4.65007 17.6901 4.51007 17.5701C4.20007 17.3001 4.16007 16.8301 4.43007 16.5101L9.02007 11.1501C9.52007 10.5701 10.2401 10.2201 11.0001 10.1901C11.7601 10.1701 12.5101 10.4501 13.0501 10.9901L14.0001 11.9401C14.2501 12.1901 14.5701 12.3101 14.9301 12.3101C15.2801 12.3001 15.6001 12.1401 15.8301 11.8701L20.4201 6.51008C20.6901 6.20008 21.1601 6.16006 21.4801 6.43006C21.7901 6.70006 21.8301 7.17006 21.5601 7.49006L16.9701 12.8501C16.4701 13.4301 15.7501 13.7801 14.9901 13.8101C14.2201 13.8301 13.4801 13.5501 12.9401 13.0101L12.0001 12.0601C11.7501 11.8101 11.4201 11.6801 11.0701 11.6901C10.7201 11.7001 10.4001 11.8601 10.1701 12.1301L5.58007 17.4901C5.42007 17.6601 5.21007 17.7501 5.00007 17.7501Z" fill="currentColor"/>
</svg>



const icon5 = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.4" d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2Z" fill="currentColor"/>
<path d="M7 14.3499C6.59 14.3499 6.25 14.0099 6.25 13.5999V10.3999C6.25 9.9899 6.59 9.6499 7 9.6499C7.41 9.6499 7.75 9.9899 7.75 10.3999V13.6099C7.75 14.0199 7.41 14.3499 7 14.3499Z" fill="currentColor"/>
<path d="M12 16.0899C11.59 16.0899 11.25 15.7499 11.25 15.3399V8.65991C11.25 8.24991 11.59 7.90991 12 7.90991C12.41 7.90991 12.75 8.24991 12.75 8.65991V15.3399C12.75 15.7499 12.41 16.0899 12 16.0899Z" fill="currentColor"/>
<path d="M17 14.3499C16.59 14.3499 16.25 14.0099 16.25 13.5999V10.3999C16.25 9.9899 16.59 9.6499 17 9.6499C17.41 9.6499 17.75 9.9899 17.75 10.3999V13.6099C17.75 14.0199 17.41 14.3499 17 14.3499Z" fill="currentColor"/>
</svg>



const icon6 = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22 10.9699V13.03C22 13.58 21.56 14.0299 21 14.0499H19.0399C17.9599 14.0499 16.97 13.2599 16.88 12.1799C16.82 11.5499 17.0599 10.9599 17.4799 10.5499C17.8499 10.1699 18.36 9.94995 18.92 9.94995H21C21.56 9.96995 22 10.4199 22 10.9699Z" fill="currentColor"/>
<path opacity="0.4" d="M17.48 10.55C17.06 10.96 16.82 11.55 16.88 12.18C16.97 13.26 17.96 14.05 19.04 14.05H21V15.5C21 18.5 19 20.5 16 20.5H7C4 20.5 2 18.5 2 15.5V8.5C2 5.78 3.64 3.88 6.19 3.56C6.45 3.52 6.72 3.5 7 3.5H16C16.26 3.5 16.51 3.50999 16.75 3.54999C19.33 3.84999 21 5.76 21 8.5V9.95001H18.92C18.36 9.95001 17.85 10.17 17.48 10.55Z" fill="currentColor"/>
<path d="M13 9.75H7C6.59 9.75 6.25 9.41 6.25 9C6.25 8.59 6.59 8.25 7 8.25H13C13.41 8.25 13.75 8.59 13.75 9C13.75 9.41 13.41 9.75 13 9.75Z" fill="currentColor"/>
</svg>



const icon7 = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.4" d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2Z" fill="currentColor"/>
<path d="M6.87988 18.9001C6.46988 18.9001 6.12988 18.5601 6.12988 18.1501V16.0801C6.12988 15.6701 6.46988 15.3301 6.87988 15.3301C7.28988 15.3301 7.62988 15.6701 7.62988 16.0801V18.1501C7.62988 18.5701 7.28988 18.9001 6.87988 18.9001Z" fill="currentColor"/>
<path d="M12 18.9C11.59 18.9 11.25 18.56 11.25 18.15V14C11.25 13.59 11.59 13.25 12 13.25C12.41 13.25 12.75 13.59 12.75 14V18.15C12.75 18.57 12.41 18.9 12 18.9Z" fill="currentColor"/>
<path d="M17.1201 18.8999C16.7101 18.8999 16.3701 18.5599 16.3701 18.1499V11.9299C16.3701 11.5199 16.7101 11.1799 17.1201 11.1799C17.5301 11.1799 17.8701 11.5199 17.8701 11.9299V18.1499C17.8701 18.5699 17.5401 18.8999 17.1201 18.8999Z" fill="currentColor"/>
<path d="M17.87 5.8201C17.87 5.7701 17.85 5.7101 17.84 5.6601C17.83 5.6201 17.82 5.5701 17.81 5.5301C17.79 5.4901 17.76 5.4601 17.74 5.4201C17.71 5.3801 17.68 5.3301 17.64 5.3001C17.63 5.2901 17.63 5.2801 17.62 5.2801C17.59 5.2601 17.56 5.2501 17.53 5.2301C17.49 5.2001 17.44 5.1701 17.39 5.1501C17.34 5.1301 17.29 5.1301 17.24 5.1201C17.2 5.1101 17.17 5.1001 17.13 5.1001H14.2C13.79 5.1001 13.45 5.4401 13.45 5.8501C13.45 6.2601 13.79 6.6001 14.2 6.6001H15.45C13.07 9.1001 10.07 10.8601 6.69998 11.7101C6.29998 11.8101 6.04998 12.2201 6.14998 12.6201C6.22998 12.9601 6.53998 13.1901 6.87998 13.1901C6.93998 13.1901 6.99998 13.1801 7.05998 13.1701C10.63 12.2801 13.82 10.4301 16.37 7.8101V8.7801C16.37 9.1901 16.71 9.5301 17.12 9.5301C17.53 9.5301 17.87 9.1901 17.87 8.7801V5.8501C17.87 5.8401 17.87 5.8301 17.87 5.8201Z" fill="currentColor"/>
</svg>


const icon8 = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.4" d="M10.96 2.10989L5.46005 4.16989C4.41005 4.56989 3.55005 5.80989 3.55005 6.93989V15.0399C3.55005 15.8499 4.08005 16.9199 4.73005 17.3999L10.23 21.5099C11.2 22.2399 12.79 22.2399 13.76 21.5099L19.26 17.3999C19.91 16.9099 20.4401 15.8499 20.4401 15.0399V6.93989C20.4401 5.81989 19.58 4.56988 18.53 4.17988L13.03 2.11989C12.47 1.89989 11.53 1.89989 10.96 2.10989Z" fill="currentColor"/>
<path d="M14.5 10.5C14.5 9.12 13.38 8 12 8C10.62 8 9.5 9.12 9.5 10.5C9.5 11.62 10.24 12.55 11.25 12.87V15.5C11.25 15.91 11.59 16.25 12 16.25C12.41 16.25 12.75 15.91 12.75 15.5V12.87C13.76 12.55 14.5 11.62 14.5 10.5Z" fill="currentColor"/>
</svg>

const icon9 = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.4" d="M21.0799 8.58003V15.42C21.0799 16.54 20.4799 17.58 19.5099 18.15L13.5699 21.58C12.5999 22.14 11.3999 22.14 10.4199 21.58L4.47991 18.15C3.50991 17.59 2.90991 16.55 2.90991 15.42V8.58003C2.90991 7.46003 3.50991 6.41999 4.47991 5.84999L10.4199 2.42C11.3899 1.86 12.5899 1.86 13.5699 2.42L19.5099 5.84999C20.4799 6.41999 21.0799 7.45003 21.0799 8.58003Z" fill="currentColor"/>
<path d="M11.9999 12.0001C13.2867 12.0001 14.3299 10.9569 14.3299 9.67004C14.3299 8.38322 13.2867 7.34009 11.9999 7.34009C10.7131 7.34009 9.66992 8.38322 9.66992 9.67004C9.66992 10.9569 10.7131 12.0001 11.9999 12.0001Z" fill="currentColor"/>
<path d="M14.6799 16.6601C15.4899 16.6601 15.9599 15.7601 15.5099 15.0901C14.8299 14.0801 13.5099 13.4001 11.9999 13.4001C10.4899 13.4001 9.16992 14.0801 8.48992 15.0901C8.03992 15.7601 8.50992 16.6601 9.31992 16.6601H14.6799Z" fill="#292D32"/>
</svg>


const icon10 = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.4" d="M17.98 10.79V14.79C17.98 15.05 17.97 15.3 17.94 15.54C17.71 18.24 16.12 19.58 13.19 19.58H12.79C12.54 19.58 12.3 19.7 12.15 19.9L10.95 21.5C10.42 22.21 9.56 22.21 9.03 21.5L7.82999 19.9C7.69999 19.73 7.41 19.58 7.19 19.58H6.79001C3.60001 19.58 2 18.79 2 14.79V10.79C2 7.86001 3.35001 6.27001 6.04001 6.04001C6.28001 6.01001 6.53001 6 6.79001 6H13.19C16.38 6 17.98 7.60001 17.98 10.79Z" fill="currentColor"/>
<path d="M9.98999 14C9.42999 14 8.98999 13.55 8.98999 13C8.98999 12.45 9.43999 12 9.98999 12C10.54 12 10.99 12.45 10.99 13C10.99 13.55 10.55 14 9.98999 14Z" fill="currentColor"/>
<path d="M13.49 14C12.93 14 12.49 13.55 12.49 13C12.49 12.45 12.94 12 13.49 12C14.04 12 14.49 12.45 14.49 13C14.49 13.55 14.04 14 13.49 14Z" fill="currentColor"/>
<path d="M6.5 14C5.94 14 5.5 13.55 5.5 13C5.5 12.45 5.95 12 6.5 12C7.05 12 7.5 12.45 7.5 13C7.5 13.55 7.05 14 6.5 14Z" fill="currentColor"/>
<path d="M21.98 6.79001V10.79C21.98 13.73 20.63 15.31 17.94 15.54C17.97 15.3 17.98 15.05 17.98 14.79V10.79C17.98 7.60001 16.38 6 13.19 6H6.79004C6.53004 6 6.28004 6.01001 6.04004 6.04001C6.27004 3.35001 7.86004 2 10.79 2H17.19C20.38 2 21.98 3.60001 21.98 6.79001Z" fill="currentColor"/>
</svg>






