import { Flex, Image, Text, Box, Spinner } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import Header from "../components/header";
import { AuthConnect } from "../util/request";
import Table from "../components/table";
import BackButton from "../components/back";
import paper from "../assets/images/Paper.svg"
import Status from "../components/status";
import Card from "../components/card";
import { declineIcon, acceptIcon, kycIcon, actionIcon, locIcon } from "../components/svgs";

export default function JobsTab({ fid }) {
    const [screen, setScreen] = useState(0)
    const [data, setData] = useState()
    const [filter, setFilter] = useState("Filter")
    const [loading, setLoading] = useState(false)
    const [loading2, setLoading2] = useState(false)
    const [loading3, setLoading3] = useState(false)
    const [loading4, setLoading4] = useState(false)
    const [currentData, setCurrentData] = useState("")
    const [lastScroll, setLastScroll] = useState()

    async function loadData(direction, id) {
        direction === "prev" ? setLoading3(true) : direction === "next" ? setLoading2(true) : setLoading(true)
        const res = await AuthConnect("get", direction === "prev" ? "/customer-verifications-tasks?page[size]=20&expand[fields]=verification_id&verification_id="+fid+"&sort[fields]=created_at:desc&page[cursor]="+id+"&page[forwards]="+false : direction === "next" ? "/customer-verifications-tasks?page[size]=20&expand[fields]=verification_id&verification_id="+fid+"&sort[fields]=created_at:desc&page[cursor]="+id+"&page[forwards]="+true : "/customer-verifications-tasks?page[size]=20&expand[fields]=verification_id&sort[fields]=created_at:desc&verification_id="+fid)
        setData(res)
        console.log(res)
        direction === "prev" ? setLoading3(false) : direction === "next" ? setLoading2(false) : setLoading(false)
    }

    useEffect(() => {
        loadData()
    }, [])
    
    const tabs = ["All"]

    const tableHead = ["Doc Type", "ID", "In Review", "Date Created", "Status"]

    const ref1 = useRef("")

    async function updateScreen(item) {
        /*setLastScroll(window.scrollY)
        setScreen(1)*/
        setCurrentData(item)
    }




    //****************** OTHER SECTION ************************************ */
    /*useEffect(() => {
        if(screen === 1) {
            window.scrollTo(0,0)
        }
    }, [screen])

    const pData = [{title: "Document Type:", val: currentData?.data?.document_type},{title: "ID:", val: currentData?.data?.id},{title: "In Review:", val: currentData?.data?.in_review ? "Yes" : "No"},{title: "Date Created:", val: currentData?.data?.created_at?.substr(0,10)},{title: "Status:", val: <Status status={currentData?.data?.status ?? "QUEUED"} />},{title: "Date Updated:", val: currentData?.data?.updated_at?.substr(0,10)},{title: "Verification ID:", val: currentData?.data?.verification_id},{title: "SID:", val: currentData?.data?.sid},]

    useEffect(async () => {
        if(screen === 1) {
            setLoading4(true)
            const res = await AuthConnect("get", "/customer-verifications-tasks/"+currentData?.id)
            setCurrentData(res)
            setLoading4(false)
        }
    }, [screen])*/




    return (
        <Flex w="100%" direction="column">
            
            {/******* TABLES PAGE ********* */}
            <Flex w="100%" direction="column" display={screen === 0 ? "flex" : "none"} mt="-8">
                <Table type="jobs" data={data} filter={filter} setFilter={setFilter} updateScreen={updateScreen} loading={loading} loading2={loading2} loading3={loading3} loadData={loadData} tabs={tabs} tableHead={tableHead} inpRef={ref1} setData={setData} setLoading={setLoading} />
            </Flex>







            {/******* OVERVIEW ********* */}
            {/*<Flex w="100%" direction="column" display={screen === 1 ? "flex" : "none"}>
                <BackButton setAction={setScreen} caption="Kyc Jobs" lastScroll={lastScroll} />
                
                <Flex w="100%" my="48px" justify="space-between">
                    <Flex mr="12">
                        <Header title="KYC Job Details" sub="Manage users kyc jobs here" />
                    </Flex>
                </Flex>



                <Flex w="100%" justify="space-between" align="flex-start">
                    <Flex w="100%">
                        <Card arr={pData} label="KYC Details" type="personal" loading={loading4} />
                    </Flex> 
                </Flex>


            </Flex>*/}

        </Flex>
    )
}