import { Flex, Image, Text, Box, Spinner } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import Header from "../components/header";
import { AuthConnect } from "../util/request";
import Table from "../components/table";
import BackButton from "../components/back";
import Card from "../components/card";
import male from "../assets/images/male.png"
import female from "../assets/images/female.webp"
import bman from "../assets/images/businessman.png"

export default function Assets() {
    const [screen, setScreen] = useState(0)
    const [data, setData] = useState()
    const [filter, setFilter] = useState("Filter")
    const [loading, setLoading] = useState(false)
    const [loading2, setLoading2] = useState(false)
    const [loading3, setLoading3] = useState(false)
    const [loading4, setLoading4] = useState(true)
    const [currentData, setCurrentData] = useState("")
    const [lastScroll, setLastScroll] = useState()

    async function loadData(direction, id) {
        direction === "prev" ? setLoading3(true) : direction === "next" ? setLoading2(true) : setLoading(true)
        const res = await AuthConnect("get", direction === "prev" ? "/customer-holdings?page[size]=20&page[cursor]="+id+"&page[forwards]="+false : direction === "next" ? "/customer-holdings?page[size]=20&page[cursor]="+id+"&page[forwards]="+true : "/customer-holdings?page[size]=20")
        setData(res)
        direction === "prev" ? setLoading3(false) : direction === "next" ? setLoading2(false) : setLoading(false)
    }

    useEffect(() => {
        loadData()
    }, [])


    const tabs = ["All"]
    const tableHead = ["Assets", "Abbreviation", "Type", "Balance", "Funding balance", "Spot balance"]

    const ref1 = useRef("")

    async function updateScreen(item) {
        setLastScroll(window.scrollY)
        setScreen(1)
        setCurrentData(item)
    }




    //****************** OTHER SECTION ************************************ */
    useEffect(() => {
        if(screen === 0) {
            setLoading4(true)
        }
        else if(screen === 1) {
            window.scrollTo(0,0)
        }
    }, [screen])


    const pData = [{title: "Full Name:", val: currentData?.expansions?.holder[0]?.profile?.fullname},{title: "User ID:", val: currentData?.data?.holder_id},{title: "Phone number:", val: currentData?.expansions?.holder[0]?.phone_number},{title: "Email address:", val: currentData?.expansions?.holder[0]?.email_address},{title: "Country:", val: currentData?.expansions?.holder[0]?.country}]


    const wData = [{title: "Currency Type:", val: currentData?.data?.currency_type},{title: "Balance:", val: parseFloat(currentData?.data?.balance)?.toLocaleString()+" "+currentData?.data?.currency},{title: "Funding Balance:", val: parseFloat(currentData?.data?.funding_balance)?.toLocaleString()+" "+currentData?.data?.currency},{title: "Spot Balance:", val: parseFloat(currentData?.data?.spot_balance)?.toLocaleString()+" "+currentData?.data?.currency},{title: "Wallet ID:", val: currentData?.data?.wallet_id},{title: "Created:", val: currentData?.data?.created_at?.substr(0,10)},]


    useEffect(async () => {
        if(screen === 1) {
            setLoading4(true)
            const res = await AuthConnect("get", "/customer-holdings/"+currentData?.id+"?expand[fields]=wallet_id,holder_id")
            setCurrentData(res)
            setLoading4(false)
        }
    }, [screen])


    return (
        <Flex w="100%" direction="column">
            
            {/******* TABLES PAGE ********* */}
            <Flex w="100%" direction="column" display={screen === 0 ? "flex" : "none"}>
                <Header title="Assets" sub="View assets here" />

                <Table type="assets" data={data} filter={filter} setFilter={setFilter} updateScreen={updateScreen} loading={loading} loading2={loading2} loading3={loading3} loadData={loadData} tabs={tabs} tableHead={tableHead} inpRef={ref1} setData={setData} setLoading={setLoading} />
            </Flex>




            {/******* OVERVIEW ********* */}
            <Flex w="100%" direction="column" display={screen === 1 ? "flex" : "none"}>
                <BackButton setAction={setScreen} caption="Asset profile" lastScroll={lastScroll} />

                                
                <Flex w="100%" my="48px" justify="space-between">
                    {!loading4 && 
                    <Flex>
                        <Image bgColor="#6421F214" src={currentData?.expansions?.holder[0]?.profile?.gender === null ? bman : currentData?.expansions?.holder[0]?.profile?.gender === "Female" ? female : male} w="115px" h="115px" borderRadius="100%" />
                        <Text ml="6">
                            <Text fontSize="40px" fontWeight="700" color="#212B36">{currentData?.expansions?.holder[0]?.profile?.fullname}</Text>
                            <Text color="#637381" fontSize="16px">User ID: {currentData?.expansions?.holder[0]?.id}</Text>
                            <Text color="#212B36" fontSize="14px">Joined: {currentData?.expansions?.holder[0]?.created_at?.substr(0,10)}</Text>
                        </Text>
                    </Flex>}
                </Flex>

                {!loading4 && <Text color="#212B36" fontSize="24px" fontWeight="700" mb="30px">Assets</Text>}

                <Flex w="100%" justify="space-between" align="flex-start">
                    {
                        loading4 ?
                        <Flex w="100%" justify="center" py="100px" direction="column" align="center">
                            <Spinner color="#6421F2" emptyColor="lightgrey" w="50px" h="50px" />
                            <Text mt="4" fontSize="14px" color="#637381" textAlign="center">Fetching this user assets details</Text>
                        </Flex>
                        :
                        <>
                            <Flex w="49%"><Card arr={wData} label="Asset Details" type="wallet" /></Flex>        
                            <Flex w="48%"><Card arr={pData} label="User Details" type="personal" /></Flex> 
                        </>
                    }
                </Flex>
            </Flex>
        </Flex>
    )
}