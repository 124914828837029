import { Flex, Image, Text, Spinner } from "@chakra-ui/react";
import { AuthConnect } from "../../util/request";
import { verifyIcon } from "../svgs";
import { useState, useEffect, useRef } from "react";





export default function ApproveKyc({ modal, setModal }) {
    const [loading, setLoading] = useState(false)

    async function modalFunc() {
        setLoading(true)
        const res = await AuthConnect("put", "/customers/"+modal?.data+"/profile/social-profiles/verification?passed="+true)
        await modal.func(true)
        setModal({ isOpen: false })
        setModal({
            isOpen: true,
            id: "SUCCESS",
            data: ["Approved", "You have successfully approved this kyc social media"]
        })
        setLoading(false)
    }

    return (
        <Flex w="100%" direction="column">
            <Flex w="100%" direction="column" align="center">
                {verifyIcon}
                <Text textAlign="center" mt="5" fontSize="20px" color="#212B36" fontWeight="600">Are you sure you want to approve this?</Text>
                <Text mt="2" px="5" textAlign="center" fontWeight="500" color="#637381" fontSize="16px">By approving this, the user’s social media information will be updated and active</Text>
            </Flex>

            <Flex w="100%" justify="space-between" mt="8">
                <Flex cursor="pointer" bg="#fff" border="1px solid #D0D5DD" color="#212B36" borderRadius="8px" padding="10px 18px" w="48.5%" justify="center" align="center" fontWeight="500" boxShadow="0px 1px 2px 0px #1018280D" onClick={() => setModal({
                    isOpen: false,
                })}>Cancel</Flex>

                <Flex cursor="pointer" bg="#6421F2" border="1px solid #6421F2" color="#fff" borderRadius="8px" padding="10px 18px" w="48.5%" justify="center" align="center" fontWeight="500" onClick={() => modalFunc()}>{loading ? <Spinner color="#fff" emptyColor="lightgrey" /> : "Yes, approve"}</Flex>
            </Flex>
        </Flex>
    )
}