export const accIcon = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.4" d="M22 7.81V16.19C22 19 20.71 20.93 18.44 21.66C17.78 21.89 17.02 22 16.19 22H7.81C6.98 22 6.22 21.89 5.56 21.66C3.29 20.93 2 19 2 16.19V7.81C2 4.17 4.17 2 7.81 2H16.19C19.83 2 22 4.17 22 7.81Z" fill="#98A2B3"/>
<path d="M18.4401 21.66C17.7801 21.89 17.0201 22 16.1901 22H7.81006C6.98006 22 6.22006 21.89 5.56006 21.66C5.91006 19.02 8.67006 16.97 12.0001 16.97C15.3301 16.97 18.0901 19.02 18.4401 21.66Z" fill="#98A2B3"/>
<path d="M15.58 11.58C15.58 13.56 13.98 15.17 12 15.17C10.02 15.17 8.42004 13.56 8.42004 11.58C8.42004 9.60002 10.02 8 12 8C13.98 8 15.58 9.60002 15.58 11.58Z" fill="#98A2B3"/>
</svg>


export const locIcon = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.4" d="M20.62 8.45C19.57 3.83 15.54 1.75 12 1.75C12 1.75 12 1.75 11.99 1.75C8.45997 1.75 4.41997 3.82 3.36997 8.44C2.19997 13.6 5.35997 17.97 8.21997 20.72C9.27997 21.74 10.64 22.25 12 22.25C13.36 22.25 14.72 21.74 15.77 20.72C18.63 17.97 21.79 13.61 20.62 8.45Z" fill="#98A2B3"/>
<path d="M12 13.4599C13.7397 13.4599 15.15 12.0496 15.15 10.3099C15.15 8.57022 13.7397 7.15991 12 7.15991C10.2603 7.15991 8.84998 8.57022 8.84998 10.3099C8.84998 12.0496 10.2603 13.4599 12 13.4599Z" fill="#98A2B3"/>
</svg>



export const acceptIcon = <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 18.3332C14.5834 18.3332 18.3334 14.5832 18.3334 9.99984C18.3334 5.4165 14.5834 1.6665 10 1.6665C5.41669 1.6665 1.66669 5.4165 1.66669 9.99984C1.66669 14.5832 5.41669 18.3332 10 18.3332Z" stroke="#027A48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M6.45831 9.99993L8.81665 12.3583L13.5416 7.6416" stroke="#027A48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>



export const declineIcon =  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 18.3332C14.5834 18.3332 18.3334 14.5832 18.3334 9.99984C18.3334 5.4165 14.5834 1.6665 10 1.6665C5.41669 1.6665 1.66669 5.4165 1.66669 9.99984C1.66669 14.5832 5.41669 18.3332 10 18.3332Z" stroke="#B42318" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M7.64166 12.3583L12.3583 7.6416" stroke="#B42318" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12.3583 12.3583L7.64166 7.6416" stroke="#B42318" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>



export const kycIcon = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.4" d="M21 7V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V7C3 4 4.5 2 8 2H16C19.5 2 21 4 21 7Z" fill="#98A2B3"/>
<path d="M18.5 9.25H16.5C14.98 9.25 13.75 8.02 13.75 6.5V4.5C13.75 4.09 14.09 3.75 14.5 3.75C14.91 3.75 15.25 4.09 15.25 4.5V6.5C15.25 7.19 15.81 7.75 16.5 7.75H18.5C18.91 7.75 19.25 8.09 19.25 8.5C19.25 8.91 18.91 9.25 18.5 9.25Z" fill="#98A2B3"/>
<path d="M12 13.75H8C7.59 13.75 7.25 13.41 7.25 13C7.25 12.59 7.59 12.25 8 12.25H12C12.41 12.25 12.75 12.59 12.75 13C12.75 13.41 12.41 13.75 12 13.75Z" fill="#98A2B3"/>
<path d="M16 17.75H8C7.59 17.75 7.25 17.41 7.25 17C7.25 16.59 7.59 16.25 8 16.25H16C16.41 16.25 16.75 16.59 16.75 17C16.75 17.41 16.41 17.75 16 17.75Z" fill="#98A2B3"/>
</svg>


export const actionIcon = <svg width="15" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.1 1.45825L8.66666 6.89159C8.02499 7.53325 6.97499 7.53325 6.33333 6.89159L0.899994 1.45825" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
</svg>




export const errorIcon = <svg width="80" height="81" viewBox="0 0 80 81" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M65.0334 20.0001L45.2334 8.56675C42.0001 6.70008 38.0001 6.70008 34.7334 8.56675L14.9667 20.0001C11.7334 21.8667 9.7334 25.3334 9.7334 29.1001V51.9001C9.7334 55.6334 11.7334 59.1001 14.9667 61.0001L34.7667 72.4334C38.0001 74.3001 42.0001 74.3001 45.2667 72.4334L65.0667 61.0001C68.3001 59.1334 70.3001 55.6668 70.3001 51.9001V29.1001C70.2667 25.3334 68.2667 21.9001 65.0334 20.0001ZM37.5001 26.3334C37.5001 24.9667 38.6334 23.8334 40.0001 23.8334C41.3667 23.8334 42.5001 24.9667 42.5001 26.3334V43.8334C42.5001 45.2001 41.3667 46.3334 40.0001 46.3334C38.6334 46.3334 37.5001 45.2001 37.5001 43.8334V26.3334ZM43.0667 55.9334C42.9001 56.3334 42.6667 56.7001 42.3667 57.0334C41.7334 57.6667 40.9001 58.0001 40.0001 58.0001C39.5667 58.0001 39.1334 57.9001 38.7334 57.7334C38.3001 57.5667 37.9667 57.3334 37.6334 57.0334C37.3334 56.7001 37.1001 56.3334 36.9001 55.9334C36.7334 55.5334 36.6667 55.1001 36.6667 54.6668C36.6667 53.8001 37.0001 52.9334 37.6334 52.3001C37.9667 52.0001 38.3001 51.7668 38.7334 51.6001C39.9667 51.0667 41.4334 51.3667 42.3667 52.3001C42.6667 52.6334 42.9001 52.9668 43.0667 53.4001C43.2334 53.8001 43.3334 54.2334 43.3334 54.6668C43.3334 55.1001 43.2334 55.5334 43.0667 55.9334Z" fill="#F04438"/>
</svg>


export const successIcon = <svg width="68" height="68" viewBox="0 0 68 68" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M34.0003 0.666748C15.6337 0.666748 0.666992 15.6334 0.666992 34.0001C0.666992 52.3668 15.6337 67.3334 34.0003 67.3334C52.367 67.3334 67.3337 52.3668 67.3337 34.0001C67.3337 15.6334 52.367 0.666748 34.0003 0.666748ZM49.9337 26.3334L31.0337 45.2334C30.567 45.7001 29.9337 45.9668 29.267 45.9668C28.6003 45.9668 27.967 45.7001 27.5003 45.2334L18.067 35.8001C17.1003 34.8334 17.1003 33.2334 18.067 32.2667C19.0337 31.3001 20.6337 31.3001 21.6003 32.2667L29.267 39.9334L46.4003 22.8001C47.367 21.8334 48.967 21.8334 49.9337 22.8001C50.9003 23.7668 50.9003 25.3334 49.9337 26.3334Z" fill="#12B76A"/>
</svg>


export const verifyIcon = <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M71.8669 35.8001L67.3336 30.5334C66.4669 29.5334 65.7669 27.6668 65.7669 26.3334V20.6668C65.7669 17.1334 62.8669 14.2334 59.3336 14.2334H53.6669C52.3669 14.2334 50.4669 13.5334 49.4669 12.6668L44.2003 8.13345C41.9003 6.16678 38.1336 6.16678 35.8003 8.13345L30.5669 12.7001C29.5669 13.5334 27.6669 14.2334 26.3669 14.2334H20.6003C17.0669 14.2334 14.1669 17.1334 14.1669 20.6668V26.3668C14.1669 27.6668 13.4669 29.5334 12.6336 30.5334L8.13359 35.8334C6.20026 38.1334 6.20026 41.8668 8.13359 44.1668L12.6336 49.4668C13.4669 50.4668 14.1669 52.3335 14.1669 53.6334V59.3334C14.1669 62.8668 17.0669 65.7668 20.6003 65.7668H26.3669C27.6669 65.7668 29.5669 66.4668 30.5669 67.3335L35.8336 71.8668C38.1336 73.8335 41.9003 73.8335 44.2336 71.8668L49.5003 67.3335C50.5003 66.4668 52.3669 65.7668 53.7003 65.7668H59.3669C62.9003 65.7668 65.8003 62.8668 65.8003 59.3334V53.6668C65.8003 52.3668 66.5003 50.4668 67.3669 49.4668L71.9003 44.2001C73.8336 41.9001 73.8336 38.1001 71.8669 35.8001ZM53.8669 33.7001L37.7669 49.8001C37.3003 50.2668 36.6669 50.5334 36.0003 50.5334C35.3336 50.5334 34.7003 50.2668 34.2336 49.8001L26.1669 41.7334C25.2003 40.7668 25.2003 39.1668 26.1669 38.2001C27.1336 37.2334 28.7336 37.2334 29.7003 38.2001L36.0003 44.5001L50.3336 30.1668C51.3003 29.2001 52.9003 29.2001 53.8669 30.1668C54.8336 31.1334 54.8336 32.7334 53.8669 33.7001Z" fill="#12B76A"/>
</svg>

export const walletIcon = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22 10.9702V13.0302C22 13.5802 21.56 14.0302 21 14.0502H19.04C17.96 14.0502 16.97 13.2602 16.88 12.1802C16.82 11.5502 17.06 10.9602 17.48 10.5502C17.85 10.1702 18.36 9.9502 18.92 9.9502H21C21.56 9.9702 22 10.4202 22 10.9702Z" fill="#98A2B3"/>
<path opacity="0.4" d="M17.48 10.55C17.06 10.96 16.82 11.55 16.88 12.18C16.97 13.26 17.96 14.05 19.04 14.05H21V15.5C21 18.5 19 20.5 16 20.5H7C4 20.5 2 18.5 2 15.5V8.5C2 5.78 3.64 3.88 6.19 3.56C6.45 3.52 6.72 3.5 7 3.5H16C16.26 3.5 16.51 3.50999 16.75 3.54999C19.33 3.84999 21 5.76 21 8.5V9.95001H18.92C18.36 9.95001 17.85 10.17 17.48 10.55Z" fill="#98A2B3"/>
<path d="M13 9.75H7C6.59 9.75 6.25 9.41 6.25 9C6.25 8.59 6.59 8.25 7 8.25H13C13.41 8.25 13.75 8.59 13.75 9C13.75 9.41 13.41 9.75 13 9.75Z" fill="#98A2B3"/>
</svg>


export const ngnIcon = <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13 26C20.1797 26 26 20.1797 26 13C26 5.8203 20.1797 0 13 0C5.8203 0 0 5.8203 0 13C0 20.1797 5.8203 26 13 26Z" fill="#32D583"/>
<path d="M8.952 19V15.542H8.14V14.506H8.952V13.498H8.14V12.462H8.952V9.004H11.15L12.242 12.462H13.516V9.004H15.07V12.462H15.868V13.498H15.07V14.506H15.868V15.542H15.07V19H12.858L11.738 15.542H10.506V19H8.952ZM10.506 14.506H11.43L11.122 13.498H10.478L10.506 14.506ZM13.558 16.816H13.628L13.572 15.542H13.194L13.558 16.816ZM10.45 12.462H10.842L10.436 11.118H10.38L10.45 12.462ZM12.9 14.506H13.544L13.516 13.498H12.578L12.9 14.506Z" fill="white"/>
</svg>


export function showTime(time) {
    const date = new Date(time);
    const ampmTime = date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    return ampmTime?.replace(/\s+/g, '');
}

export function showDate(date) {
    const newDate = new Date(date);
    const formattedDate = newDate.toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric' });
    return formattedDate;
}


export const crpto_arr = {
    "ETH": "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png",
    "SOL": "https://s2.coinmarketcap.com/static/img/coins/64x64/5426.png",
    "BTC": "https://s2.coinmarketcap.com/static/img/coins/64x64/1.png",
    "USDT": "https://s2.coinmarketcap.com/static/img/coins/64x64/825.png",
    "BNB": "https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png",
    "DOGE": "https://s2.coinmarketcap.com/static/img/coins/64x64/74.png"
}


export const ass1 = <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="24" cy="24" r="24" fill="#FFECE0"/>
<path d="M34 34H14C13.59 34 13.25 33.66 13.25 33.25C13.25 32.84 13.59 32.5 14 32.5H34C34.41 32.5 34.75 32.84 34.75 33.25C34.75 33.66 34.41 34 34 34Z" fill="#FF7F36"/>
<path d="M21.75 16V34H26.25V16C26.25 14.9 25.8 14 24.45 14H23.55C22.2 14 21.75 14.9 21.75 16Z" fill="#FF7F36"/>
<path d="M15 22V34H19V22C19 20.9 18.6 20 17.4 20H16.6C15.4 20 15 20.9 15 22Z" fill="#FF7F36"/>
<path d="M29 27V34H33V27C33 25.9 32.6 25 31.4 25H30.6C29.4 25 29 25.9 29 27Z" fill="#FF7F36"/>
</svg>


export const ass2 = <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="24.3333" cy="24" r="24" fill="#E9F2FF"/>
<path d="M29 32.5H19C16 32.5 14 31 14 27.5V20.5C14 17 16 15.5 19 15.5H29C32 15.5 34 17 34 20.5V27.5C34 31 32 32.5 29 32.5Z" fill="#0064F0" stroke="#003E94" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M24 27C25.6569 27 27 25.6569 27 24C27 22.3431 25.6569 21 24 21C22.3431 21 21 22.3431 21 24C21 25.6569 22.3431 27 24 27Z" fill="#0064F0" stroke="#003E94" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14 21H15C18 21 19 20 19 17V16" fill="#0064F0"/>
<path d="M14 21H15C18 21 19 20 19 17V16" stroke="#003E94" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M34 21H33C30 21 29 20 29 17V16" fill="#0064F0"/>
<path d="M34 21H33C30 21 29 20 29 17V16" stroke="#003E94" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14 27H15C18 27 19 28 19 31V32" stroke="#003E94" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M34 27H33C30 27 29 28 29 31V32" stroke="#003E94" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
</svg>


export const ass3 = <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="24.6665" cy="24" r="24" fill="#E2FEF0"/>
<path d="M27.97 24.75H27.2H22.53V27.58H23.84H27.97C28.92 27.58 29.7 26.94 29.7 26.16C29.7 25.38 28.92 24.75 27.97 24.75Z" fill="#05944F"/>
<path d="M25 14C19.48 14 15 18.48 15 24C15 29.52 19.48 34 25 34C30.52 34 35 29.52 35 24C35 18.48 30.52 14 25 14ZM27.97 29.08H26.32V30.5C26.32 30.91 25.98 31.25 25.57 31.25C25.16 31.25 24.82 30.91 24.82 30.5V29.08H23.84H23.61V30.5C23.61 30.91 23.27 31.25 22.86 31.25C22.45 31.25 22.11 30.91 22.11 30.5V29.08H21.78H20.05C19.64 29.08 19.3 28.74 19.3 28.33C19.3 27.92 19.64 27.58 20.05 27.58H21.03V24V20.42H20.05C19.64 20.42 19.3 20.08 19.3 19.67C19.3 19.26 19.64 18.92 20.05 18.92H21.78H22.11V17.5C22.11 17.09 22.45 16.75 22.86 16.75C23.27 16.75 23.61 17.09 23.61 17.5V18.92H23.84H24.82V17.5C24.82 17.09 25.16 16.75 25.57 16.75C25.98 16.75 26.32 17.09 26.32 17.5V18.92H27.2C28.75 18.92 30.12 20.28 30.12 21.84C30.12 22.51 29.88 23.12 29.5 23.62C30.51 24.11 31.2 25.07 31.2 26.18C31.2 27.77 29.75 29.08 27.97 29.08Z" fill="#05944F"/>
<path d="M28.62 21.8302C28.62 21.1702 28 20.4102 27.2 20.4102H23.84H22.53V23.2402H27.2C27.98 23.2502 28.62 22.6102 28.62 21.8302Z" fill="#05944F"/>
</svg>




