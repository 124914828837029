import { Flex, Image, Text, Box, Spinner } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import Header from "../components/header";
import { AuthConnect } from "../util/request";
import Table from "../components/table";
import ViewAdmin from "../components/modals/viewAdmin";

export default function Admin({ setModal }) {
    const [screen, setScreen] = useState(0)
    const [data, setData] = useState()
    const [filter, setFilter] = useState("Filter")
    const [loading, setLoading] = useState(false)
    const [loading2, setLoading2] = useState(false)
    const [loading3, setLoading3] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [currentData, setCurrentData] = useState("")
    const [roles, setRoles] = useState("")

    async function loadData(direction, id, noLoad) {
        noLoad ? setLoading(false) : direction === "prev" ? setLoading3(true) : direction === "next" ? setLoading2(true) : setLoading(true)

        const res = await AuthConnect("get", direction === "prev" ? "/administrators?page[size]=20&page[cursor]="+id+"&page[forwards]="+false : direction === "next" ? "/administrators?page[size]=20&page[cursor]="+id+"&page[forwards]="+true : "/administrators?page[size]=20")

        setData(res)
        direction === "prev" ? setLoading3(false) : direction === "next" ? setLoading2(false) : setLoading(false)
    }

    async function loadRoles() {
        const res = await AuthConnect("get", "/administrators/roles")
        setRoles(res?.data)
    }

    useEffect(() => {
        loadData()
        loadRoles()
    }, [])


    const tabs = ["All"]
    const tableHead = ["Fullname", "Email address", "ID", "Role", "Date Created", "Activated"]

    const ref1 = useRef("")

    async function updateScreen(item) {
        setIsOpen(true)
        setCurrentData(item)
    }

    return (
        <Flex w="100%" direction="column">
        {isOpen && <ViewAdmin setIsOpen={setIsOpen} data={currentData} setModal={setModal} loadData={loadData} />}
            
            {/******* TABLES PAGE ********* */}
            <Flex w="100%" direction="column" display={screen === 0 ? "flex" : "none"}>
                <Header title="Admin users" sub="Manage admin users here" type="admin" setModal={setModal} loadData={loadData} roles={roles} />

                <Table type="admin" data={data} filter={filter} setFilter={setFilter} updateScreen={updateScreen} loading={loading} loading2={loading2} loading3={loading3} loadData={loadData} tabs={tabs} tableHead={tableHead} inpRef={ref1} setData={setData} setLoading={setLoading} />
            </Flex>




            {/******* OVERVIEW ********* */}
            <Flex w="100%" direction="column" display={screen === 1 ? "flex" : "none"}></Flex>
        </Flex>
    )
}