import { Flex, Image, Text, Spinner } from "@chakra-ui/react";
import { AuthConnect } from "../../util/request";
import { verifyIcon } from "../svgs";
import { useState, useEffect, useRef } from "react";
import InputHolder from "../inputHolder";





export default function CreateUser({ modal, setModal }) {
    const [loading, setLoading] = useState(false)
    const ref1 = useRef("")
    const ref2 = useRef("")
    const ref3 = useRef("")
    const ref4 = useRef("")

    async function modalFunc(e) {
        e.preventDefault()
        setLoading(true)

        function findIdByName(roles, name) {
            const role = roles.find(role => role.name === name);
            return role ? role.id : null;
        }

        const res = await AuthConnect("post", "/accounts/create", {
            role_id: findIdByName(roles, ref4.current.value),
            email_address: ref3.current.value,
            first_name: ref1.current.value,
            last_name: ref2.current.value
        })
        if(res) {
            await modal.reload(null, null, true)
            setModal({ isOpen: false })
            setModal({
                isOpen: true,
                id: "SUCCESS",
                data: ["Invited User", "You have successfully invited this user"]
            })
        }
        setLoading(false)
    }
    const roles = modal?.data

    return (
        <Flex w="100%" direction="column" mt="-10px">
            <Text color="#212B36" fontSize="18px" fontWeight="700">Invite user</Text>

            <form onSubmit={(e) => modalFunc(e)} style={{ width: "100%" }}>
                <Flex w="100%" direction="column" mt="6">
                    <InputHolder inpRef={ref1} label="First name" type="text" required />
                    <InputHolder inpRef={ref2} label="Last name" type="text" required />
                    <InputHolder inpRef={ref3} label="Email" type="email" required />
                    <InputHolder type="select" inpRef={ref4} options={roles?.map((item) => {
                        return item?.name
                    })} empty="" label="Select roles" />
                </Flex>

                <Flex w="100%" justify="space-between" mt="8">
                    <Flex cursor="pointer" bg="#fff" border="1px solid #D0D5DD" color="#212B36" borderRadius="8px" padding="10px 18px" w="48.5%" justify="center" align="center" fontWeight="500" boxShadow="0px 1px 2px 0px #1018280D" onClick={() => setModal({
                        isOpen: false,
                    })}>Cancel</Flex>

                    <Flex as="button" cursor="pointer" bg="#6421F2" border="1px solid #6421F2" color="#fff" borderRadius="8px" padding="10px 18px" w="48.5%" justify="center" align="center" fontWeight="500">{loading ? <Spinner color="#fff" emptyColor="lightgrey" /> : "Invite"}</Flex>
                </Flex>
            </form>
        </Flex>
    )
}