import { Flex, Image, Text, Spinner } from "@chakra-ui/react";
import { AuthConnect } from "../../util/request";
import { errorIcon } from "../svgs";
import InputHolder from "../inputHolder";
import { useState, useEffect, useRef } from "react";
import Toast from "../notify";





export default function Deactivate({ modal, setModal }) {
    const [loading, setLoading] = useState(false)
    const ref1 = useRef("")

    async function modalFunc() {
        if(ref1.current.value !== "") {
            setLoading(true)
            const res = await AuthConnect("put", "/customers/"+modal?.data?.id+"/moderation", {
                deactivation: {
                    value: true,
                    reason: ref1.current.value
                }
            })
            if(res) {
                await modal.func()
                modal.reload !== undefined && modal.reload()
                modal.refresh !== undefined && modal.refresh(modal?.data?.moderation_id)
                setModal({ isOpen: false })
                setModal({
                    isOpen: true,
                    id: "SUCCESS",
                    data: ["Deactivated", "You have successfully deactivated this user"]
                })
            }
            setLoading(false)
        }
        else {
            Toast("Kindly choose a reason to continue", "error")
        }
    }

    return (
        <Flex w="100%" direction="column">
            <Flex w="100%" direction="column" align="center">
                {errorIcon}
                <Text textAlign="center" mt="5" fontSize="24px" color="#212B36" fontWeight="600">Deactivate User</Text>
                <Text mt="2" px="5" textAlign="center" fontWeight="500" color="#637381" fontSize="16px">This user will no longer be active and account will be temporarily locked</Text>
            </Flex>

            <Flex w="100%" mt="4"><InputHolder type="select" inpRef={ref1} options={["fraud"]} empty="Choose a reason" /></Flex>

            <Flex w="100%" justify="space-between" mt="6">
                <Flex cursor="pointer" bg="#fff" border="1px solid #D0D5DD" color="#212B36" borderRadius="8px" padding="10px 18px" w="48.5%" justify="center" align="center" fontWeight="500" boxShadow="0px 1px 2px 0px #1018280D" onClick={() => setModal({
                    isOpen: false,
                })}>Cancel</Flex>

                <Flex cursor="pointer" bg="#6421F2" border="1px solid #6421F2" color="#fff" borderRadius="8px" padding="10px 18px" w="48.5%" justify="center" align="center" fontWeight="500" onClick={() => modalFunc()}>{loading ? <Spinner color="#fff" emptyColor="lightgrey" /> : "Deactivate"}</Flex>
            </Flex>
        </Flex>
    )
}