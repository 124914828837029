import { Flex, Image, Text, Box, Spinner } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import Header from "../components/header";
import { AuthConnect } from "../util/request";
import Table from "../components/table";
import fin1 from "../assets/images/fin1.svg"
import fin2 from "../assets/images/fin2.svg"
import fin3 from "../assets/images/fin3.svg"
import BackButton from "../components/back";
import FinopsModal from "../components/modals/finopsModal";
import { crpto_arr } from "../components/svgs";

var page = 1
export default function FinOps() {
    const [screen, setScreen] = useState(0)
    const [oScreen, setOScreen] = useState(0)
    const [data, setData] = useState()
    const [data2, setData2] = useState()
    const [filter, setFilter] = useState("Filter")
    const [filter2, setFilter2] = useState("Filter")
    const [loading, setLoading] = useState(false)
    const [loading2, setLoading2] = useState(false)
    const [loading3, setLoading3] = useState(false)
    const [loading4, setLoading4] = useState(false)
    const [loading5, setLoading5] = useState(false)
    const [loading6, setLoading6] = useState(false)
    const [loading7, setLoading7] = useState(false)
    const [bal, setBal] = useState("")
    const [link, setLink] = useState("")
    const [currentData, setCurrentData] = useState("")
    const [isOpen, setIsOpen] = useState(false)

    async function loadData(direction) {
        if(direction === "prev") {
            page--
        }
        if(direction === "next") {
            page++
        }
        direction === "prev" ? setLoading3(true) : direction === "next" ? setLoading2(true) : setLoading(true)
        const res = await AuthConnect("get", direction === "prev" ? "/finops/"+link+"/deposits?page="+page+"&per_page=10" : direction === "next" ? "/finops/"+link+"/deposits?page="+page+"&per_page=10" : "/finops/"+link+"/deposits?page=1&per_page=10")
        setData(res)
        direction === "prev" ? setLoading3(false) : direction === "next" ? setLoading2(false) : setLoading(false)
    }

    async function loadData2(direction) {
        if(direction === "prev") {
            page--
        }
        if(direction === "next") {
            page++
        }
        direction === "prev" ? setLoading7(true) : direction === "next" ? setLoading6(true) : setLoading5(true)
        const res = await AuthConnect("get", direction === "prev" ? "/finops/"+link+"/withdrawals?page="+page+"&per_page=10" : direction === "next" ? "/finops/"+link+"/withdrawals?page="+page+"&per_page=10" : "/finops/"+link+"/withdrawals?page=1&per_page=10")
        setData2(res)
        direction === "prev" ? setLoading7(false) : direction === "next" ? setLoading6(false) : setLoading5(false)
    }


    async function loadBalances() {
        setLoading4(true)
        const res = await AuthConnect("get", "/finops/"+link+"/balances")
        if(res) {
            setBal(res?.data)
        }
        setLoading4(false)
    }

    useEffect(() => {
        setOScreen(0)
        setLoading4(false)
        if(screen === 1) {
            loadBalances()
            loadData()
            loadData2()
        }
        else if(screen === 0) {
            setBal("")
            setData("")
            setData2("")
        }
    }, [screen])


    const tabs = ["All"]
    const tableHead = link === "flutterwave" ? ["Charged Amount", "Fee", "Payment Type", "Settled Amount", "Date Created", "Status",] : ["Amount", "Currency", "User", "Address", "Done On", "Status",]

    const tabs2 = ["All"]
    const tableHead2 = ["Amount", "Account Name", "Bank Name", "Fee", "Date Created", "Status",]

    const ref1 = useRef("")
    const ref2 = useRef("")

    async function updateScreen(item) {
        setIsOpen(true)
        setCurrentData(item)
    }

    const finArr = [{name: "Zine Bot", icon: fin1},{name: "Quidax", icon: fin2},{name: "Flutterwave", icon: fin3}]

    const oTabs = ["Deposits", "Withdrawals"]

    function getAb(abbreviation) {
        const currency = bal.find(crypto => crypto.currency === abbreviation);
        return currency ? currency.available_balance : "___";
    }

    function getLb(abbreviation) {
        const currency = bal.find(crypto => crypto.currency === abbreviation);
        return currency ? currency.ledger_balance : "___";
    }


    function getCryptoImage(key) {
        return crpto_arr[key] || null;
    }



    return (
        <Flex w="100%" direction="column">
            {isOpen && <FinopsModal setIsOpen={setIsOpen} data={currentData} type={oScreen === 0 ? "deposit" : "withdraw"} pd={link} />}
            
            {/******* TABLES PAGE ********* */}
            <Flex w="100%" direction="column" display={screen === 0 ? "flex" : "none"}>
                <Header title="Finance Ops" sub="View assets data here" />
                <Flex w="100%" flexWrap="wrap" mt="6">
                    {
                        finArr.map((item, index) => (
                            <Flex key={index} w="252px" padding="16px 8px" boxShadow="0px 12px 24px -4px #919EAB1F, 0px 0px 2px 0px #919EAB33" borderRadius="8px" direction="column" mr="8" cursor="pointer" onClick={() => {
                                setScreen(1)
                                setLink(index === 0 ? "zine-bot" : index === 1 ? "quidax" : "flutterwave")
                            }}>
                                <Image w="100%" src={item.icon} borderRadius="4px" />
                                <Flex px="3" mt="5" mb="2" w="100%" justify="space-between">
                                    <Text color="#212B36" fontWeight="700" fontSize="18px">{item.name}</Text>
                                    <Flex cursor="pointer" padding="6px 10px" bg="#6421F214" borderRadius='6px' fontSize='12px' color="#6421F2" fontWeight="700">View Details</Flex>
                                </Flex>
                            </Flex>
                        ))
                    }
                </Flex>
            </Flex>




            {/******* OVERVIEW ********* */}
            <Flex w="100%" direction="column" display={screen === 1 ? "flex" : "none"}>
                <BackButton setAction={setScreen} caption={link === "zine-bot" ? "Zine Bot" : link === "quidax" ? "Quidax" : "Flutterwave"} />

                <Flex py="48px" align="center">
                    <Flex w="80px" h="80px" backgroundImage={link === "zine-bot" ? fin1 : link === "quidax" ? fin2 : fin3} backgroundSize="cover" backgroundPosition="center center" backgroundRepeat="no-repeat" borderRadius="100%"></Flex>

                    <Text ml="6" fontSize="48px" color="#212B36" fontWeight="700">{link === "zine-bot" ? "Zine Bot" : link === "quidax" ? "Quidax" : "Flutterwave"}</Text>
                </Flex>

                {
                    loading4 ? 
                    <Flex minH="170px" direction="column" mt="-50px" py="50px">
                        <Flex w="50%" h="10px" borderRadius="10px" bg="#EDF2F7"></Flex>
                        <Flex w="30%" my="auto" h="10px" borderRadius="10px" bg="#EDF2F7"></Flex>
                        <Flex w="20%" h="10px" borderRadius="10px" bg="#EDF2F7"></Flex>
                    </Flex>
                    :
                    (screen === 1 && bal !== "") ?
                    <Box w="100%" mb="6" minH="100px" overflowY="hidden" h="auto" position="relative" className="customScroll" overflowX="scroll">
                        <Flex minW="3000px" position="absolute" align="center">
                                {
                                    bal?.sort(({ledger_balance:a}, {ledger_balance:b}) => b-a)?.map((item, index) => (
                                        <Flex key={index} w="300px" mr="5" bg="#F9FAFB" borderRadius="8px" align="flex-start" padding="16px" border="1px solid #EDF2F7">

                                            {getCryptoImage(item?.currency) === null ? <Flex bg="#6421F214" w="40px" h="40px" borderRadius="100%" justify="center" align="center" fontSize="12px" color="#6421F2" fontWeight="700">{item?.currency}</Flex> : <Image src={getCryptoImage(item?.currency)} w="40px" h="40px" />
                                            }

                                            <Text ml="3">
                                                <Text color="#637381" fontSize="12px">Total Ledger Balance</Text>
                                                <Text fontWeight="700" color="#071827" fontSize="20px">{parseFloat(item?.ledger_balance)?.toLocaleString()+" "+item?.currency}</Text>
                                            </Text>
                                        </Flex>
                                    ))
                                }
                            </Flex>
                    </Box>
                    :
                    <Flex minH="170px" direction="column" mt="-50px" py="50px" pr="50%">
                        <Text color="#637381" pl="110px">Balance is currently unavailable for this service</Text>
                    </Flex>
                }

                <Flex w="100%" borderBottom="2px solid #919EAB14" px="20px" position="sticky" top="80px" zIndex="15" bg="#fff">
                    {
                        oTabs.map((item, index) => (
                            <Flex mr="60px" py="16px" cursor="pointer" color={oScreen === index ? "#212B36" : "#667085"} borderBottom={oScreen === index ? "2px solid #212B36" : "2px solid transparent"} fontWeight={oScreen === index ? "700" : "500"} onClick={() => setOScreen(index)} transition="200ms ease-in-out" _hover={{ color: "#212B36", borderBottom: "2px solid #212B36", fontWeight: "700"}}>{item}</Flex>
                        ))
                    }
                </Flex>
                
                <Flex w="100%" direction="column">
                    {
                        
                        (oScreen === 0 &&
                        screen === 1) ? (
                            <>
                                <Table type={link === "flutterwave" ? "finopsFlutterwave1" : link === "quidax" ? "finopsQuidax1" : "finops-deposit"} data={data} filter={filter} setFilter={setFilter} updateScreen={updateScreen} loading={loading} loading2={loading2} loading3={loading3} loadData={loadData} tabs={tabs} tableHead={tableHead} inpRef={ref1} />
                            </>
                        )
                        :
                        (oScreen === 1 &&
                        screen === 1) && (
                            <>
                                <Table type={link === "flutterwave" ? "finopsFlutterwave2" : "finops-withdrawal"} data={data2} filter={filter2} setFilter={setFilter2} updateScreen={updateScreen} loading={loading5} loading2={loading6} loading3={loading7} loadData={loadData2} tabs={tabs2} tableHead={tableHead2} inpRef={ref2} />
                            </>
                        )
                    }
                </Flex>
            </Flex>
        </Flex>
    )
}