import { Flex, Image, Text, Spinner } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import Header from "../header";
import Status from "../status";
import { useEffect } from "react";


export default function FinopsModal({ setIsOpen, data, type, pd }) {

    const mArr = (type === "deposit" && pd === "flutterwave") ? [
        {name: "Amount:", val: parseFloat(data?.amount)?.toLocaleString()},
        {name: "Charged Amount:", val: parseFloat(data?.charged_amount)?.toLocaleString()},
        {name: "Date Created:", val: data?.created_at?.substr(0,10)},
        {name: "FLW Fee:", val: parseFloat(data?.flw_fee)?.toLocaleString()},
        {name: "FLW Ref:", val: data?.flw_ref},
        {name: "Narration:", val: data?.narration},
        {name: "Country:", val: data?.payment_channel?.payment_card?.country},
        {name: "Status:", val: <Status status={data?.status} />},
        {name: "Settled Amount:", val: parseFloat(data?.settled_amount)?.toLocaleString()},
        {name: "Zine Fee:", val: parseFloat(data?.zine_fee)?.toLocaleString()},
    ]
    :(type === "deposit" && pd === "quidax") ? [
        {name: "Amount:", val: parseFloat(data?.amount)?.toLocaleString()+" "+data?.currency},
        {name: "Currency:", val: data?.currency},
        {name: "Email:", val: data?.destination_user?.email},
        {name: "Fullname:", val: data?.destination_user?.first_name + " " + data?.destination_user?.last_name},
        {name: "Network:", val: data?.destination_wallet?.default_network},
        {name: "Address:", val: data?.destination_wallet?.deposit_address},
        {name: "Done On:", val: data?.done_at?.substr(0,10)},
        {name: "Status:", val: <Status status={data?.status} />},
        {name: "Fee:", val: parseFloat(data?.fee)?.toLocaleString()},
        {name: "Sender:", val: data?.sender},
        {name: "Reason:", val: data?.reason},
        {name: "Type:", val: data?.type},
    ]
    :
    (type === "withdraw" && pd === "flutterwave") ? [
        {name: "Account Name:", val: data?.account_name},
        {name: "Account Number:", val: data?.account_number},
        {name: "Amount:", val: parseFloat(data?.amount)?.toLocaleString()},
        {name: "Bank Name:", val: data?.bank_name},
        {name: "Date Created:", val: data?.created_at?.substr(0,10)},
        {name: "Currency:", val: data?.currency},
        {name: "Debit Currency:", val: data?.debit_currency},
        {name: "Fee:", val: parseFloat(data?.fee)?.toLocaleString()},
        {name: "Narration:", val: data?.narration},
        {name: "Status", val: <Status status={data?.status} />},
        {name: "Zine Fee", val: parseFloat(data?.zine_fee)?.toLocaleString()},
    ]
    :
    [{name: "Transaction ID:", val: data?.data?.id}]

    return (
        <Flex w="100%" bg="rgba(22, 28, 36, .8)" position="fixed" top="0" left="0" h="100%" justify="flex-end" zIndex="1000">
            <Flex overflowY="scroll" bg="#fff" padding="40px" w="598px" minH="100vh" className="customScroll" boxShadow="-40px 40px 80px -8px #0000003D" direction='column' data-aos="fade-left">
                <Header title="Transaction Details" sub="View transaction details" />
                <Flex flex="1" direction="column" mt="8">
                    {
                        mArr.map((item, index) => (
                            <Flex w="100%" key={index} pb="8px" pt="10px" borderBottom="1px solid #919EAB33" justify="space-between" align="flex-start">
                                <Text fontSize="16px" color="#212B36" fontWeight="700" mr="4">{item.name}</Text>
                                <Text maxW="65%" justify="flex-end" wordBreak="break-all" fontWeight="500" color="#637381" fontSize="16px">{item.val}</Text>
                            </Flex>
                        ))
                    }
                </Flex>
                <Flex w="100%" mt="auto">
                    <Flex mt="5" cursor="pointer" bg="#6421F2" color="#fff" ml="auto" borderRadius="8px" padding="10px 18px" w="100%" justify="center" align="center" fontWeight="500" onClick={() => setIsOpen(false)}>Okay</Flex>
                </Flex>
            </Flex>
        </Flex>
    )
}