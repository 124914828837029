import { Flex, Image, Text } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import paper from "../assets/images/Paper.svg"
import user from "../assets/images/user.png"
import { AuthConnect } from "../util/request";

export default function NavBar({ setModal }) {
    const [menu, setMenu] = useState(false)
    const [menu2, setMenu2] = useState(false)
    const [data, setData] = useState("")
    const history = useHistory()

    function signOut() {
        sessionStorage.clear()
        history.push("/")
    }

    function findArr(dataArray, type) {
        return dataArray.find(item => item.type === type);
    }

    async function checkAlert() {
        const res = await AuthConnect("get", "/alerts")
        setData(res?.data)
        const data = findArr(res?.data, "ASSET_THRESHOLD")
        if(data !== undefined) {
            setModal({
                isOpen: true,
                id: "LOW_BAL",
                data: [data?.type, data?.description],
            })
        }
    }

    useEffect(() => {
        checkAlert()
    }, [])

    useEffect(() => {
        if(menu) {
            setMenu2(false)
        }
        if(menu2) {
            setMenu(false)
        }
    }, [menu, menu2])
    
    return (
        <Flex w='100%' px="40px" py="24px" justify="flex-end" align="center" position="sticky" top="0" zIndex="20" bg="#fff">
            <Flex position="relative">
                <Flex mr="4" cursor="pointer" onClick={() => menu2 ? setMenu2(false) : setMenu2(true)} align="flex-end"><Flex flex="1">{icon1}</Flex> {Array.isArray(data) && <Flex mb="1" bg="#B42318" color="#fff" fontWeight="bold" justify="center" align="center" w="12px" h="12px" borderRadius="100%" ml="-1.5" fontSize="9px">+</Flex>}</Flex>
                {
                    menu2 && <Flex backgroundImage={paper} backgroundSize="cover" backgroundRepeat="no-repeat" data-aos="fade-up" data-aos-duration="400" mt="12" ml="-60px" bgColor="#fff" position="absolute" w="200px" borderRadius="8px" boxShadow="-20px 20px 40px -4px #919EAB3D, 0px 0px 2px 0px #919EAB3D" direction="column" maxH="70vh">
                        {
                            Array.isArray(data) ?
                            <>
                                {
                                    data?.map((item, index) => (
                                    <Text key={index} cursor="pointer" padding="15px 12px" fontSize="12px" color="#637381" _hover={{ color: "#6421F2", fontWeight: "500" }} transition="200ms ease-inb-out">
                                        <Text fontSize="11px" fontWeight="bold">{item?.type}</Text>
                                        <Text>{item?.description}</Text>
                                    </Text>
                                    ))
                                }
                            </>
                            :
                            <Text py="8" px="5">No notifications</Text>
                        }
                    </Flex>
                }
            </Flex>

            <Text mr="4" cursor="pointer">{icon2}</Text>

            <Flex position="relative">
                <Flex w="40px" h="40px" backgroundImage={user} backgroundSize="cover" backgroundRepeat="no-repeat" borderRadius="100%" border="4px solid #F4EBFF" bgColor="#637381" cursor="pointer" onClick={() => menu ? setMenu(false) : setMenu(true)}></Flex>
                {
                    menu && <Flex backgroundImage={paper} backgroundSize="cover" backgroundRepeat="no-repeat" data-aos="fade-up" data-aos-duration="400" mt="12" right="-10px" bgColor="#fff" position="absolute" w="100px" borderRadius="8px" boxShadow="-20px 20px 40px -4px #919EAB3D, 0px 0px 2px 0px #919EAB3D" direction="column">
                        <Text cursor="pointer" w="100%" textAlign="center" px="5" py="4" onClick={() => signOut()}>Sign Out</Text>
                    </Flex>
                }
            </Flex>
        </Flex>
    )
}


const icon1 =  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.4" d="M20.4 16.33C20.12 17.08 19.53 17.65 18.76 17.91C17.68 18.27 16.57 18.54 15.45 18.73C15.34 18.75 15.23 18.7701 15.12 18.7801C14.94 18.8101 14.76 18.83 14.58 18.85C14.36 18.8801 14.13 18.9 13.9 18.92C13.27 18.97 12.65 19 12.02 19C11.38 19 10.74 18.97 10.11 18.91C9.83996 18.89 9.57996 18.8601 9.31996 18.82C9.16996 18.8 9.01996 18.7801 8.87996 18.76C8.76996 18.74 8.65996 18.73 8.54996 18.71C7.43996 18.53 6.33996 18.26 5.26996 17.9C4.46996 17.63 3.85996 17.06 3.58996 16.33C3.31996 15.61 3.41996 14.77 3.84996 14.05L4.97996 12.17C5.21996 11.76 5.43996 10.97 5.43996 10.49V8.63005C5.43996 5.00005 8.38996 2.05005 12.02 2.05005C15.64 2.05005 18.59 5.00005 18.59 8.63005V10.49C18.59 10.97 18.81 11.76 19.06 12.17L20.19 14.05C20.6 14.75 20.68 15.57 20.4 16.33Z" fill="#292D32"/>
<path d="M12 10.7599C11.58 10.7599 11.24 10.4199 11.24 9.99989V6.89989C11.24 6.47989 11.58 6.13989 12 6.13989C12.42 6.13989 12.76 6.47989 12.76 6.89989V9.99989C12.75 10.4199 12.41 10.7599 12 10.7599Z" fill="#292D32"/>
<path d="M14.8299 20.01C14.4099 21.17 13.2999 22 11.9999 22C11.2099 22 10.4299 21.68 9.87993 21.11C9.55993 20.81 9.31993 20.41 9.17993 20C9.30993 20.02 9.43993 20.03 9.57993 20.05C9.80993 20.08 10.0499 20.11 10.2899 20.13C10.8599 20.18 11.4399 20.21 12.0199 20.21C12.5899 20.21 13.1599 20.18 13.7199 20.13C13.9299 20.11 14.1399 20.1 14.3399 20.07C14.4999 20.05 14.6599 20.03 14.8299 20.01Z" fill="#292D32"/>
</svg>


const icon2 = <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path opacity="0.4" d="M2 12.8799V11.1199C2 10.0799 2.85 9.21994 3.9 9.21994C5.71 9.21994 6.45 7.93994 5.54 6.36994C5.02 5.46994 5.33 4.29994 6.24 3.77994L7.97 2.78994C8.76 2.31994 9.78 2.59994 10.25 3.38994L10.36 3.57994C11.26 5.14994 12.74 5.14994 13.65 3.57994L13.76 3.38994C14.23 2.59994 15.25 2.31994 16.04 2.78994L17.77 3.77994C18.68 4.29994 18.99 5.46994 18.47 6.36994C17.56 7.93994 18.3 9.21994 20.11 9.21994C21.15 9.21994 22.01 10.0699 22.01 11.1199V12.8799C22.01 13.9199 21.16 14.7799 20.11 14.7799C18.3 14.7799 17.56 16.0599 18.47 17.6299C18.99 18.5399 18.68 19.6999 17.77 20.2199L16.04 21.2099C15.25 21.6799 14.23 21.3999 13.76 20.6099L13.65 20.4199C12.75 18.8499 11.27 18.8499 10.36 20.4199L10.25 20.6099C9.78 21.3999 8.76 21.6799 7.97 21.2099L6.24 20.2199C5.33 19.6999 5.02 18.5299 5.54 17.6299C6.45 16.0599 5.71 14.7799 3.9 14.7799C2.85 14.7799 2 13.9199 2 12.8799Z" fill="#292D32"/>
<path d="M12 15.25C13.7949 15.25 15.25 13.7949 15.25 12C15.25 10.2051 13.7949 8.75 12 8.75C10.2051 8.75 8.75 10.2051 8.75 12C8.75 13.7949 10.2051 15.25 12 15.25Z" fill="#292D32"/>
</svg>