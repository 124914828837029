import { Flex, Image, Text, Spinner } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { AuthConnect } from "../../util/request";
import { useEffect, useState } from "react";
import Header from "../header";
import Status from "../status";
import Toast from "../notify";


export default function ViewAdmin({ setIsOpen, data, loadData, setModal }) {
    const [loading, setLoading] = useState(false)
    const [loading2, setLoading2] = useState(false)
    const [mData, setMdata] = useState(false)
    const [isPending, setIsPending] = useState(false)

    

    useEffect(async () => {
        setLoading(true)
        const res = await AuthConnect("get", "/administrators/"+data?.id)
        setMdata(res)
        setLoading(false)
    }, [])

    async function reloadFunc() {
        const res = await AuthConnect("get", "/administrators/"+data?.id)
        setMdata(res)
    }


    const mArr = [
        {name: "Fullname:", val: mData?.data?.first_name + " " + mData?.data?.last_name},
        {name: "Email address:", val: mData?.data?.email_address},
        {name: "Activated Status:", val: <Status status={mData?.data?.is_activated ? "Yes" : "No"} />},
        {name: "ID:", val: mData?.data?.id},
        {name: "Role:", val: mData?.data?.role},
        {name: "Disabled:", val:  <Status status={mData?.data?.is_disabled ? "Yes" : "No"} />},
        {name: "Date Created:", val: mData?.data?.created_at?.substr(0,10)},
        {name: "SID:", val: mData?.data?.sid},
        {name: "Date Updated:", val: mData?.data?.updated_at?.substr(0,10)}
    ]

    return (
        <Flex w="100%" bg="rgba(22, 28, 36, .8)" position="fixed" top="0" left="0" h="100%" justify="flex-end" zIndex="999">
            <Flex overflowY="scroll" bg="#fff" padding="40px" w="598px" minH="100vh" className="customScroll" boxShadow="-40px 40px 80px -8px #0000003D" direction='column' data-aos="fade-left">
                <Header title="Admin Details" sub="View this admin user details" />
                <Flex flex="1" direction="column" mt="8">
                    {
                        loading ?
                        <Flex w="100%" justify="center" py="100px">
                            <Spinner color="#6421F2" emptyColor="lightgrey" w="50px" h="50px" />
                        </Flex>
                        :
                        mData ?
                        mArr.map((item, index) => (
                            <Flex w="100%" key={index} pb="8px" pt="10px" borderBottom="1px solid #919EAB33" justify="space-between" align="flex-start">
                                <Text fontSize="16px" color="#212B36" fontWeight="700" mr="4">{item.name}</Text>
                                <Text maxW="65%" justify="flex-end" wordBreak="break-all" fontWeight="500" color="#637381" fontSize="16px">{item.val}</Text>
                            </Flex>
                        ))
                        :
                        <Flex w="100%" justify="center" py="100px" color="#637381" fontWeight="500">No Data</Flex>
                    }
                </Flex>
                <Flex w="100%" mt="auto">
                    {
                        mData?.data?.is_disabled ?
                        <Flex mt="5" mr="5" cursor="pointer" bg="#027A48" color="#fff" ml="auto" borderRadius="8px" padding="10px 18px" w="100%" justify="center" align="center" fontWeight="500" onClick={() => setModal({
                            isOpen: true,
                            id: "ACTIVATE",
                            data: mData?.data,
                            func: loadData,
                            reload: reloadFunc,
                        })}>Activate Admin</Flex>
                        :
                        mData?.data?.is_disabled === false &&
                        <Flex mt="5" mr="5" cursor="pointer" bg="#B42318" color="#fff" ml="auto" borderRadius="8px" padding="10px 18px" w="100%" justify="center" align="center" fontWeight="500" onClick={() => setModal({
                            isOpen: true,
                            id: "DEACTIVATE",
                            data: mData?.data,
                            func: loadData,
                            reload: reloadFunc,
                        })}>Disable Admin</Flex> 
                    }
                    <Flex mt="5" cursor="pointer" bg="#6421F2" color="#fff" ml="auto" borderRadius="8px" padding="10px 18px" w="100%" justify="center" align="center" fontWeight="500" onClick={() => setIsOpen(false)}>Close</Flex>
                </Flex>
            </Flex>
        </Flex>
    )
}