import Logo from "../assets/images/logo.svg"
import { Flex, Image, Text } from "@chakra-ui/react";
import {
	Switch,
	Route,
    Redirect,
} from "react-router-dom";
import { requireAuth } from "../util/request";
import { useHistory } from "react-router-dom";
import MenuBar from "../components/menuBar";
import Users from "./users";
import Kyc from "./kyc";
import Transactions from "./transactions";
import Metrics from "./metrics";
import Wallets from "./wallet";
import Assets from "./assets";
import FinOps from "./finops";
import Audit from "./audit";
import Admin from "./admin";
import Enquiry from "./enquiry";
import { useState, useEffect } from "react";
import NavBar from "../components/navBar";
import ScrollToTop from "../components/sc";
import Modal from "../components/modals";
import Jobs from "./jobs";

function Dashboard() {
    const [modal, setModal] = useState({
        isOpen: false,
        id: "",
        data: "",
        func: "",
        refresh: "",
        reload: "",
    })


    return (
        <ScrollToTop>
        <Flex w="100%" align="flex-start">
        
            <Flex position="sticky" zIndex="11" top="0" bg="#fff" padding="24px 16px 100px 16px" borderRight="1px dashed #919EAB33" w="264px" h="100vh"  overflowY="scroll" className="customScroll">
                <MenuBar />
            </Flex>

            <Flex flex="1" direction="column" pb="80px">
                <NavBar setModal={setModal} />
                {
                    modal?.isOpen && 
                    <Modal modal={modal} setModal={setModal} />
                }
                <Flex w="100%" direction="column" px="40px">
                    <Switch>
                        <Route
                            path="/dashboard/users"
                            render={() => (
                                <Users setModal={setModal} />
                            )}
                        />
                        <Route
                            path="/dashboard/kyc"
                            render={() => (
                                <Kyc setModal={setModal} />
                            )}
                        />
                        <Route
                            path="/dashboard/jobs"
                            render={() => (
                                <Jobs setModal={setModal} />
                            )}
                        />
                        <Route
                            path="/dashboard/transactions"
                            render={() => (
                                <Transactions />
                            )}
                        />
                        <Route
                            path="/dashboard/metrics"
                            render={() => (
                                <Metrics />
                            )}
                        />
                        <Route
                            path="/dashboard/wallets"
                            render={() => (
                                <Wallets />
                            )}
                        />
                        <Route
                            path="/dashboard/assets"
                            render={() => (
                                <Assets />
                            )}
                        />
                        <Route
                            path="/dashboard/finance-ops"
                            render={() => (
                                <FinOps />
                            )}
                        />
                        <Route
                            path="/dashboard/audit-trail"
                            render={() => (
                                <Audit />
                            )}
                        />
                        <Route
                            path="/dashboard/admin"
                            render={() => (
                                <Admin setModal={setModal} />
                            )}
                        />
                        <Route
                            path="/dashboard/enquiry"
                            render={() => (
                                <Enquiry />
                            )}
                        />
                        <Redirect strict from="/dashboard" to="/dashboard/metrics" />
                    </Switch>
                </Flex>
            </Flex>
        </Flex>
        </ScrollToTop>
    )
}

export default requireAuth(Dashboard)