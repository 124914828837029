import { Flex, Image, Text, Box, Spinner } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import Header from "../components/header";
import { AuthConnect } from "../util/request";
import Table from "../components/table";
import ViewEnquiry from "../components/modals/viewEnquiry";

export default function Enquiry() {
    const [screen, setScreen] = useState(0)
    const [data, setData] = useState()
    const [filter, setFilter] = useState("Filter")
    const [loading, setLoading] = useState(false)
    const [loading2, setLoading2] = useState(false)
    const [loading3, setLoading3] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [currentData, setCurrentData] = useState("")

    async function loadData(direction, id) {
        direction === "prev" ? setLoading3(true) : direction === "next" ? setLoading2(true) : setLoading(true)

        const res = await AuthConnect("get", direction === "prev" ? "/customer-support/enquiries?page[size]=20&page[cursor]="+id+"&page[forwards]="+false : direction === "next" ? "/customer-support/enquiries?page[size]=20&page[cursor]="+id+"&page[forwards]="+true : "/customer-support/enquiries?page[size]=20")

        setData(res)
        direction === "prev" ? setLoading3(false) : direction === "next" ? setLoading2(false) : setLoading(false)
    }

    useEffect(() => {
        loadData()
    }, [])


    const tabs = ["All"]
    const tableHead = ["First Name", "Last Name", "Email address", "Phone number", "Enquiry", "Date"]

    const ref1 = useRef("")

    async function updateScreen(item) {
        setIsOpen(true)
        setCurrentData(item)
    }

    return (
        <Flex w="100%" direction="column">
        {isOpen && <ViewEnquiry setIsOpen={setIsOpen} data={currentData} />}
            
            {/******* TABLES PAGE ********* */}
            <Flex w="100%" direction="column" display={screen === 0 ? "flex" : "none"}>
                <Header title="Enquiry" sub="Manage users requests here" />

                <Table type="enquiry" data={data} filter={filter} setFilter={setFilter} updateScreen={updateScreen} loading={loading} loading2={loading2} loading3={loading3} loadData={loadData} tabs={tabs} tableHead={tableHead} inpRef={ref1} />
            </Flex>




            {/******* OVERVIEW ********* */}
            <Flex w="100%" direction="column" display={screen === 1 ? "flex" : "none"}></Flex>
        </Flex>
    )
}