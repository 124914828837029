import { Flex, Image, Text, Box, Spinner } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import Header from "../components/header";
import { AuthConnect } from "../util/request";
import Table from "../components/table";
import BackButton from "../components/back";
import Card from "../components/card";
import male from "../assets/images/male.png"
import female from "../assets/images/female.webp"
import bman from "../assets/images/businessman.png"
import { ass1, ass2, ass3, crpto_arr, showDate, showTime } from "../components/svgs";
import crypt from "../assets/images/crypt.svg"

export default function Wallets() {
    const [screen, setScreen] = useState(0)
    const [data, setData] = useState()
    const [filter, setFilter] = useState("Filter")
    const [loading, setLoading] = useState(false)
    const [loading2, setLoading2] = useState(false)
    const [loading3, setLoading3] = useState(false)
    const [loading4, setLoading4] = useState(true)
    const [loading5, setLoading5] = useState(true)
    const [currentData, setCurrentData] = useState("")
    const [lastScroll, setLastScroll] = useState()
    const [bal, setBal] = useState("")


    async function loadData(direction, id) {
        direction === "prev" ? setLoading3(true) : direction === "next" ? setLoading2(true) : setLoading(true)
        const res = await AuthConnect("get", direction === "prev" ? "/customer-wallets?page[size]=10&expand[fields]=owner_id&page[cursor]="+id+"&page[forwards]="+false : direction === "next" ? "/customer-wallets?page[size]=10&expand[fields]=owner_id&page[cursor]="+id+"&page[forwards]="+true : "/customer-wallets?page[size]=10&expand[fields]=owner_id")
        setData(res)
        direction === "prev" ? setLoading3(false) : direction === "next" ? setLoading2(false) : setLoading(false)
    }

    

    async function loadBalances() {
        setLoading5(true)
        const res = await AuthConnect("get", "/customer-wallets/metrics")
        if(res) {
            setBal(res?.data)
        }
        setLoading5(false)
    }


    useEffect(() => {
        loadData()
        loadBalances()
    }, [])

    const tabs = ["All"]

    const tableHead = ["Type", "Owner", "Currency", "Wallet Value", "Wallet Id", "Date Created", "Action"]

    const ref1 = useRef("")

    async function updateScreen(item) {
        setLastScroll(window.scrollY)
        setScreen(1)
        setCurrentData(item)
    }

    const zArr = bal !== "" && [{title: "Total Asset Under Management", icon: 
    ass1, val: bal?.total_asset_aum[0]?.currency + " " + parseFloat(bal?.total_asset_aum[0]?.amount)?.toLocaleString()}, {title: "Total  FIAT AUM", icon: ass2, val: bal?.total_fiat_aum[0]?.currency + " " + parseFloat(bal?.total_fiat_aum[0]?.amount)?.toLocaleString()}, {title: "Total Crypto AUM", icon: ass3, val: bal?.total_crypto_aum[0]?.currency + " " + parseFloat(bal?.total_crypto_aum[0]?.amount)?.toLocaleString()}]


    //****************** OTHER SECTION ************************************ */
    useEffect(() => {
        if(screen === 0) {
            setLoading4(true)
        }
        else if(screen === 1) {
            window.scrollTo(0,0)
        }
    }, [screen])


    const pData = [{title: "Full Name:", val: currentData?.expansions?.owner[0]?.profile?.fullname},{title: "User ID:", val: currentData?.data?.owner_id},{title: "Phone number:", val: currentData?.expansions?.owner[0]?.phone_number},{title: "Email address:", val: currentData?.expansions?.owner[0]?.email_address},]


    const wData = [{title: "Wallet Type:", val: currentData?.data?.type},{title: "Wallet Currency:", val: currentData?.data?.balance[0]?.currency},{title: "Wallet Balance:", val: parseFloat(currentData?.data?.balance[0]?.amount)?.toLocaleString()+" "+ currentData?.data?.balance[0]?.currency},{title: "Wallet ID:", val: currentData?.data?.id},{title: "Date Created:", val: showDate(currentData?.data?.created_at?.substr(0,10)) + " " + showTime(currentData?.data?.created_at)}, {title: "Country:", val: currentData?.expansions?.owner[0]?.country},]


    useEffect(async () => {
        if(screen === 1) {
            setLoading4(true)
            const res = await AuthConnect("get", "/customer-wallets/"+currentData?.id+"?expand[fields]=owner_id")
            setCurrentData(res)
            setLoading4(false)
        }
    }, [screen])

    function getCryptoImage(key) {
        return crpto_arr[key] || crypt;
    }


    return (
        <Flex w="100%" direction="column">
            
            {/******* TABLES PAGE ********* */}
            <Flex w="100%" direction="column" display={screen === 0 ? "flex" : "none"}>
                <Header title="Wallets" sub="Manage users’ wallets" />

                {
                    loading5 ? 
                    <Flex minH="170px" direction="column" py="50px">
                        <Flex w="50%" h="10px" borderRadius="10px" bg="#EDF2F7"></Flex>
                        <Flex w="30%" my="auto" h="10px" borderRadius="10px" bg="#EDF2F7"></Flex>
                        <Flex w="20%" h="10px" borderRadius="10px" bg="#EDF2F7"></Flex>
                    </Flex>
                    :
                    (screen === 0 && bal !== "") ?
                    <>
                        <Flex w="100%" mt="6" flexWrap="wrap" justify="space-between">
                            {
                                zArr.map((item, index) => (
                                    <Flex key={index} w="32%" border="1px solid #EDF2F7" borderRadius="8px" align="center" padding="16px">
                                        <Text>{item.icon}</Text>
                                        <Text ml="3">
                                            <Text color="#637381" fontSize="12px">{item.title}</Text>
                                            <Text fontWeight="700" color="#071827" fontSize="20px">{item.val}</Text>
                                        </Text>
                                    </Flex>
                                ))
                            }
                        </Flex>

                        <Flex w="100%" border="1px solid #EDF2F7" padding="16px" pr="0px" borderRadius="8px" mt="7" direction="column">
                            <Text fontWeight="700" color="#1E3448" fontSize="16px">Top 5 Assets</Text>
                            <Flex w="100%" mt="5" flexWrap="wrap" justify="space-between" mr="-4px">
                                {
                                    bal?.assets?.sort(({amount:a}, {amount:b}) => b-a)?.map((item, index) => (
                                        <Flex mr="20px" key={index} border="1px solid #EDF2F7" padding="8px 12px" borderRadius="8px" direction="column" display={index < 5 ? "flex" : "none"}>
                                            <Flex align="center">
                                                <Image w="24px" h="24px" src={getCryptoImage(item?.currency)} mr="3" />
                                                <Text color="#1E3448" fontSize="16px">{item?.currency}</Text>
                                            </Flex>
                                            <Text color="#637381" mt="3" fontSize="14px">TV: <Text ml="3" as="span" wordBreak="break-all" fontWeight="700">{parseFloat(item?.amount)?.toLocaleString() + " " + item?.currency}</Text></Text>
                                        </Flex>
                                    ))
                                }
                            </Flex>
                        </Flex>
                    </>
                    :
                    <Flex minH="170px" direction="column" mt="0px" py="50px" pr="50%">
                        <Text color="#637381" pl="110px">Balance is currently unavailable</Text>
                    </Flex>
                }

                <Table type="wallets" data={data} filter={filter} setFilter={setFilter} updateScreen={updateScreen} loading={loading} loading2={loading2} loading3={loading3} loadData={loadData} tabs={tabs} tableHead={tableHead} inpRef={ref1} setData={setData} setLoading={setLoading} />
            </Flex>







            {/******* OVERVIEW ********* */}
            <Flex w="100%" direction="column" display={screen === 1 ? "flex" : "none"}>
                <BackButton setAction={setScreen} caption="Wallet profile" lastScroll={lastScroll} />

                
                <Flex w="100%" my="48px" justify="space-between">
                    {!loading4 && 
                    <Flex>
                        <Image bgColor="#6421F214" src={currentData?.expansions?.owner[0]?.profile?.gender === null ? bman : currentData?.expansions?.owner[0]?.profile?.gender === "Female" ? female : male} w="115px" h="115px" borderRadius="100%" />
                        <Text ml="6">
                            <Text fontSize="40px" fontWeight="700" color="#212B36">{currentData?.expansions?.owner[0]?.profile?.fullname}</Text>
                            <Text color="#637381" fontSize="16px">User ID: {currentData?.expansions?.owner[0]?.id}</Text>
                            <Text color="#212B36" fontSize="14px">Joined: {currentData?.expansions?.owner[0]?.created_at?.substr(0,10)}</Text>
                        </Text>
                    </Flex>}
                </Flex>

                {!loading4 && <Text color="#212B36" fontSize="24px" fontWeight="700" mb="30px">Wallets</Text>}

                <Flex w="100%" justify="space-between" align="flex-start">
                    {
                        loading4 ?
                        <Flex w="100%" justify="center" py="100px" direction="column" align="center">
                            <Spinner color="#6421F2" emptyColor="lightgrey" w="50px" h="50px" />
                            <Text mt="4" fontSize="14px" color="#637381" textAlign="center">Fetching this user wallet details</Text>
                        </Flex>
                        :
                        <>
                            <Flex w="49%"><Card arr={wData} label="Wallet Details" type="wallet" /></Flex>        
                            <Flex w="48%"><Card arr={pData} label="User Details" type="personal" /></Flex> 
                        </>
                    }
                </Flex>
            </Flex>
        </Flex>
    )
}