import { Flex } from "@chakra-ui/react";

export default function Status({ status }) {
    const isOrange = ["PENDING", "QUEUED"].includes(status)
    const isRed = ["FAILED", "CANCELED", "No", "Incomplete", "Flagged as Fraud", "Deactivated", "DISMISSED", "Inactive"].includes(status)

    return (
        <Flex bg={isOrange ? "#FFFAEB" : isRed ? "#FEF3F2" : "#ECFDF3"} color={isOrange ? "#B54708" : isRed ? "#B42318" : "#027A48"} padding="2px 8px 2px 6px" borderRadius="16px" fontSize="12px" fontWeight="500" align="center" textTransform="capitalize">
            <Flex w="6px" h="6px" borderRadius="100%" mr="2" bg={isOrange ? "#F79009" : isRed ? "#F04438" : "#12B76A"}></Flex>
            <Flex flex="1" flexWrap="wrap" wordBreak="break-all">{status[0].toUpperCase() + status.slice(1).toLowerCase()}</Flex>
        </Flex>
    )
}