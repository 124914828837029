import { Flex, Image, Text, Box, Spinner } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import Header from "../components/header";
import { AuthConnect } from "../util/request";
import Table from "../components/table";

export default function Referrals({ inner, innerId }) {
    const [screen, setScreen] = useState(0)
    const [data, setData] = useState()
    const [filter, setFilter] = useState("Filter")
    const [loading, setLoading] = useState(false)
    const [loading2, setLoading2] = useState(false)
    const [loading3, setLoading3] = useState(false)
    const [zinesData, setZinesData] = useState("")

    async function loadData(direction, id) {
        direction === "prev" ? setLoading3(true) : direction === "next" ? setLoading2(true) : setLoading(true)
        const res = await AuthConnect("get", direction === "prev" ? "/customers/"+innerId+"/referrer?page[size]=20&expand[fields]=user_id,referred_by_id&page[cursor]="+id+"&page[forwards]="+false : direction === "next" ? "/customers/"+innerId+"/referrer?page[size]=20&expand[fields]=user_id,referred_by_id&page[cursor]="+id+"&page[forwards]="+true : "/customers/"+innerId+"/referrer?page[size]=20&expand[fields]=user_id,referred_by_id")
        setData(res)
        direction === "prev" ? setLoading3(false) : direction === "next" ? setLoading2(false) : setLoading(false)
    }

    useEffect(() => {
        loadData()
    }, [])


    const tabs = ["All"]
    const tableHead = ["Referred User", "User ID", "Points Earned", "Date Joined"]

    const ref1 = useRef("")

    async function updateScreen() {
        //setScreen(1)
    }

    const zArr = [
        {title: "Total Referred Users", icon: 
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="24" cy="24" r="24" fill="#E9F2FF"/>
        <path opacity="0.4" d="M20.6665 14C18.0465 14 15.9165 16.13 15.9165 18.75C15.9165 21.32 17.9265 23.4 20.5465 23.49C20.6265 23.48 20.7065 23.48 20.7665 23.49C20.7865 23.49 20.7965 23.49 20.8165 23.49C20.8265 23.49 20.8265 23.49 20.8365 23.49C23.3965 23.4 25.4065 21.32 25.4165 18.75C25.4165 16.13 23.2865 14 20.6665 14Z" fill="#003E94"/>
        <path d="M25.7465 26.1499C22.9565 24.2899 18.4065 24.2899 15.5965 26.1499C14.3265 26.9999 13.6265 28.1499 13.6265 29.3799C13.6265 30.6099 14.3265 31.7499 15.5865 32.5899C16.9865 33.5299 18.8265 33.9999 20.6665 33.9999C22.5065 33.9999 24.3465 33.5299 25.7465 32.5899C27.0065 31.7399 27.7065 30.5999 27.7065 29.3599C27.6965 28.1299 27.0065 26.9899 25.7465 26.1499Z" fill="#003E94"/>
        <path opacity="0.4" d="M31.6566 19.3401C31.8166 21.2801 30.4366 22.9801 28.5266 23.2101C28.5166 23.2101 28.5166 23.2101 28.5066 23.2101H28.4766C28.4166 23.2101 28.3566 23.2101 28.3066 23.2301C27.3366 23.2801 26.4466 22.9701 25.7766 22.4001C26.8066 21.4801 27.3966 20.1001 27.2766 18.6001C27.2066 17.7901 26.9266 17.0501 26.5066 16.4201C26.8866 16.2301 27.3266 16.1101 27.7766 16.0701C29.7366 15.9001 31.4866 17.3601 31.6566 19.3401Z" fill="#003E94"/>
        <path d="M33.6565 28.5899C33.5765 29.5599 32.9565 30.3999 31.9165 30.9699C30.9165 31.5199 29.6565 31.7799 28.4065 31.7499C29.1265 31.0999 29.5465 30.2899 29.6265 29.4299C29.7265 28.1899 29.1365 26.9999 27.9565 26.0499C27.2865 25.5199 26.5065 25.0999 25.6565 24.7899C27.8665 24.1499 30.6465 24.5799 32.3565 25.9599C33.2765 26.6999 33.7465 27.6299 33.6565 28.5899Z" fill="#003E94"/>
        </svg>
        , val: data?.data?.referral_count ? parseInt(data?.data?.referral_count)?.toLocaleString() : "0"}, 
        {title: "Total Points Earned", icon: 
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="24" cy="24" r="24" fill="#6421F2" fill-opacity="0.08"/>
        <path d="M29.53 19.77C29.46 19.76 29.39 19.76 29.32 19.77C27.77 19.72 26.54 18.45 26.54 16.89C26.54 15.3 27.83 14 29.43 14C31.02 14 32.32 15.29 32.32 16.89C32.31 18.45 31.08 19.72 29.53 19.77Z" fill="#6421F2"/>
        <path d="M32.7901 26.6999C31.6701 27.4499 30.1001 27.7299 28.6501 27.5399C29.0301 26.7199 29.2301 25.8099 29.2401 24.8499C29.2401 23.8499 29.0201 22.8999 28.6001 22.0699C30.0801 21.8699 31.6501 22.1499 32.7801 22.8999C34.3601 23.9399 34.3601 25.6499 32.7901 26.6999Z" fill="#6421F2"/>
        <path d="M18.4399 19.77C18.5099 19.76 18.5799 19.76 18.6499 19.77C20.1999 19.72 21.4299 18.45 21.4299 16.89C21.4299 15.29 20.1399 14 18.5399 14C16.9499 14 15.6599 15.29 15.6599 16.89C15.6599 18.45 16.8899 19.72 18.4399 19.77Z" fill="#6421F2"/>
        <path d="M18.5501 24.8501C18.5501 25.8201 18.7601 26.7401 19.1401 27.5701C17.7301 27.7201 16.2601 27.4201 15.1801 26.7101C13.6001 25.6601 13.6001 23.9501 15.1801 22.9001C16.2501 22.1801 17.7601 21.8901 19.1801 22.0501C18.7701 22.8901 18.5501 23.8401 18.5501 24.8501Z" fill="#6421F2"/>
        <path d="M24.12 27.87C24.04 27.86 23.95 27.86 23.86 27.87C22.02 27.81 20.55 26.3 20.55 24.44C20.56 22.54 22.09 21 24 21C25.9 21 27.44 22.54 27.44 24.44C27.43 26.3 25.97 27.81 24.12 27.87Z" fill="#6421F2"/>
        <path d="M20.87 29.9399C19.36 30.9499 19.36 32.6099 20.87 33.6099C22.59 34.7599 25.41 34.7599 27.13 33.6099C28.64 32.5999 28.64 30.9399 27.13 29.9399C25.42 28.7899 22.6 28.7899 20.87 29.9399Z" fill="#6421F2"/>
        </svg>
        , val: zinesData?.aum ?? "0"},
    ]



    return (
        <Flex w="100%" direction="column">
            
            {/******* TABLES PAGE ********* */}
            <Flex w="100%" direction="column" display={screen === 0 ? "flex" : "none"}>
                {!inner && <Header title="Referrals" sub="Manage users’ referrals" />}

                <Flex w="100%" mt="6" flexWrap="wrap" justify="space-between">
                    {
                        zArr.map((item, index) => (
                            <Flex key={index} w="49%" bg="#F9FAFB" borderRadius="8px" align="center" padding="16px" border="1px solid #EDF2F7">
                                <Text>{item.icon}</Text>
                                <Text ml="3">
                                    <Text color="#637381" fontSize="12px">{item.title}</Text>
                                    <Text fontWeight="700" color="#071827" fontSize="20px">{item.val}</Text>
                                </Text>
                            </Flex>
                        ))
                    }
                </Flex>

                <Table type="referrals" data={data} filter={filter} setFilter={setFilter} updateScreen={updateScreen} loading={loading} loading2={loading2} loading3={loading3} loadData={loadData} tabs={tabs} tableHead={tableHead} inpRef={ref1} setData={setData} setLoading={setLoading} />
            </Flex>




            {/******* OVERVIEW ********* */}
            <Flex w="100%" direction="column" display={screen === 1 ? "flex" : "none"}></Flex>
        </Flex>
    )
}