import { Flex, Image, Text, Spinner } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { accIcon, locIcon, walletIcon } from "./svgs";

export default function Card({ arr, label, type, loading }) {
    return (
        <Flex direction="column" w="100%" boxShadow="0px 12px 24px -4px #919EAB1F, 0px 0px 2px 0px #919EAB33" padding="24px 24px" borderRadius="16px">
            <Flex align='center' mb="5">
                <Text mr="5">
                   {type === "personal" ? accIcon : type === "wallet" ? walletIcon : locIcon}
                </Text>
                <Text fontSize="18px" fontWeight="700" color="#212B36">{label}</Text>
            </Flex>

            <Flex w="100%" direction="column">
                {
                    loading ?
                    <Flex w="100%" justify="center" py="50px">
                        <Spinner color="#6421F2" emptyColor="lightgrey" w="50px" h="50px" />
                    </Flex>
                    :
                    arr.map((item, index) => (
                        <Flex w="100%" key={index} pb="10px" pt="25px" borderBottom={index < (arr?.length - 1) && "1px solid #919EAB33"} justify="space-between" align="flex-start">
                            <Text fontSize="16px" color="#212B36" fontWeight="700" mr="4">{item.title}</Text>
                            <Text maxW="65%" justify="flex-end" wordBreak="break-all" fontWeight="500" color="#637381" fontSize="16px">{item.val}</Text>
                        </Flex>
                    ))
                }
            </Flex>
        </Flex>
    )
}