import { Flex, Image, Text, Box, Spinner } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import Header from "../components/header";
import { AuthConnect } from "../util/request";
import Table from "../components/table";
import BackButton from "../components/back";
import paper from "../assets/images/Paper.svg"
import Status from "../components/status";
import Card from "../components/card";
import { declineIcon, acceptIcon, kycIcon, actionIcon, locIcon } from "../components/svgs";
import JobsTab from "./jobsTab";

export default function Kyc({ setModal }) {
    const [screen, setScreen] = useState(0)
    const [oScreen, setOScreen] = useState(0)
    const [data, setData] = useState()
    const [filter, setFilter] = useState("Filter")
    const [loading, setLoading] = useState(false)
    const [loading2, setLoading2] = useState(false)
    const [loading3, setLoading3] = useState(false)
    const [loading4, setLoading4] = useState(false)
    const [currentData, setCurrentData] = useState("")
    const [fid, setFid] = useState("")
    const [menu, setMenu] = useState(false)
    const [lastScroll, setLastScroll] = useState()

    async function loadData(direction, id) {
        direction === "prev" ? setLoading3(true) : direction === "next" ? setLoading2(true) : setLoading(true)
        const res = await AuthConnect("get", direction === "prev" ? "/customer-verifications?page[size]=20&expand[fields]=user_id&page[cursor]="+id+"&page[forwards]="+false : direction === "next" ? "/customer-verifications?page[size]=20&expand[fields]=user_id&page[cursor]="+id+"&page[forwards]="+true : "/customer-verifications?page[size]=20&expand[fields]=user_id")
        setData(res)
        direction === "prev" ? setLoading3(false) : direction === "next" ? setLoading2(false) : setLoading(false)
    }

    useEffect(() => {
        loadData()
    }, [])
    
    const tabs = ["All"]

    const tableHead = ["Name", "User ID", "Account level", "Date Created", "Status"]

    const ref1 = useRef("")

    async function updateScreen(item) {
        setLastScroll(window.scrollY)
        setScreen(1)
        setCurrentData(item)
        setFid(item?.id)
    }




    //****************** OTHER SECTION ************************************ */
    useEffect(() => {
        if(screen === 1) {
            window.scrollTo(0,0)
        }
    }, [screen])

    const pData = [{title: "Full Name:", val: currentData?.expansions?.user[0]?.profile?.fullname},{title: "User ID:", val: currentData?.data?.user_id},{title: "Phone number:", val: currentData?.expansions?.user[0]?.phone_number},{title: "Email address:", val: currentData?.expansions?.user[0]?.email_address},{title: "Status:", val: <Status status={currentData?.data?.completed === true ? "Completed" : "Incomplete"} />},{title: "Level:", val: currentData?.data?.level}]

    /****{title: "BVN:", val: "___"},{title: "NIN:", val: "___"}, */


    async function loadProfile(noLoad) {
        noLoad ? setLoading4(false) : setLoading4(true)
        const res = await AuthConnect("get", "/customer-verifications/"+fid+"?expand[fields]=user_id")
        setCurrentData(res)
        setLoading4(false)
    }
    

    useEffect(() => {
        if(screen === 1) {
            loadProfile()
        }
        setMenu(false)
    }, [screen])

    const oTabs = ["Kyc Details", "Kyc Jobs"]




    return (
        <Flex w="100%" direction="column">
            
            {/******* TABLES PAGE ********* */}
            <Flex w="100%" direction="column" display={screen === 0 ? "flex" : "none"}>
                <Header title="KYC Verification" sub="Manage users verifications here" />

                <Table type="kyc" data={data} filter={filter} setFilter={setFilter} updateScreen={updateScreen} loading={loading} loading2={loading2} loading3={loading3} loadData={loadData} tabs={tabs} tableHead={tableHead} inpRef={ref1} setData={setData} setLoading={setLoading} />
            </Flex>







            {/******* OVERVIEW ********* */}
            <Flex w="100%" direction="column" display={screen === 1 ? "flex" : "none"}>
                <BackButton setAction={setScreen} caption="Kyc profile" lastScroll={lastScroll} setTabs={setOScreen} />
                
                <Flex w="100%" my="48px" justify="space-between">
                    <Flex mr="12">
                        <Header title="KYC Details" sub="Manage users kyc here" />
                    </Flex>

                    <Flex ml="auto">
                        <Flex direction="column" position="relative">
                            <Flex cursor="pointer" bg="#6421F2" color="#fff" ml="auto" borderRadius="8px" padding="10px 18px" justify="center" align="center" fontWeight="500" onClick={() => menu ? setMenu(false) : setMenu(true)}>
                                Actions 
                                <Text ml="4">{actionIcon}</Text>
                            </Flex>

                            {
                                menu &&  
                                <Flex backgroundImage={paper} backgroundSize="cover" backgroundRepeat="no-repeat" data-aos="fade-down" data-aos-duration="500" mt="12" right="-5" bgColor="#fff" position="absolute" w="200px" borderRadius="8px" boxShadow="-20px 20px 40px -4px #919EAB3D, 0px 0px 2px 0px #919EAB3D" direction="column">
                                    {
                                        ((currentData?.data?.level === "TIER_3" && currentData?.expansions?.user[0]?.profile?.social_profiles?.length > 0) ?  currentData?.expansions?.user[0]?.profile?.social_profiles[0]?.verified === false ? ["Accept Social Media", "Decline Social Media"] : ["Decline Social Media"] : [""]).map((item, index) => (
                                            <Text key={index} cursor="pointer" padding="15px 12px" fontSize="14px" color="#637381" _hover={{ color: "#6421F2", fontWeight: "500" }} transition="200ms ease-inb-out" onClick={() => {
                                                if(index === 0 && item !== "") {
                                                    setModal({
                                                        isOpen: true,
                                                        id: "APPROVE_KYC",
                                                        data: fid,
                                                        func: loadProfile
                                                    })
                                                    setMenu(false)
                                                }
                                                else if(index === 1) {
                                                    setModal({
                                                        isOpen: true,
                                                        id: "DECLINE_KYC",
                                                        data: fid,
                                                        func: loadProfile
                                                    })
                                                    setMenu(false)
                                                }
                                            }}>{item}</Text>
                                        ))
                                    }
                                </Flex>
                            }
                        </Flex>
                    </Flex>
                </Flex>

                <Flex w="100%" borderBottom="2px solid #919EAB14" px="20px" mb="40px"  position="sticky" top="80px" zIndex="15" bg="#fff">
                    {
                        oTabs.map((item, index) => (
                            <Flex mr="60px" py="16px" cursor="pointer" color={oScreen === index ? "#212B36" : "#667085"} borderBottom={oScreen === index ? "2px solid #212B36" : "2px solid transparent"} fontWeight={oScreen === index ? "700" : "500"} onClick={() => setOScreen(index)} transition="200ms ease-in-out" _hover={{ color: "#212B36", borderBottom: "2px solid #212B36", fontWeight: "700"}}>{item}</Flex>
                        ))
                    }
                </Flex>

                <Flex w="100%" direction="column">
                    {
                        oScreen === 0 ?
                        <Flex w="100%" justify="space-between" align="flex-start">
                            <Flex w="49%">
                                <Card arr={pData} label="KYC Details" type="personal" loading={loading4} />
                            </Flex> 
                
                            
                            <Flex w="48%" direction="column">
                            {currentData?.data?.level === "TIER_3" && currentData?.expansions?.user[0]?.profile?.social_profiles?.length > 0 && 
                            <Flex direction="column" mb="8" w="100%" boxShadow="0px 12px 24px -4px #919EAB1F, 0px 0px 2px 0px #919EAB33" padding="24px 24px" borderRadius="16px">
                                    <Flex align='center' mb="5">
                                        <Text mr="5">{locIcon}</Text>
                                        <Text fontSize="18px" fontWeight="700" color="#212B36">Social media</Text>
                                    </Flex>

                                    <Flex w="100%" justify="space-between" align="flex-end" mt="5">
                                        <Flex direction="column" mr="6">
                                            <Text fontWeight="700" color="#212B36" fontSize="12px" textTransform="uppercase">{currentData?.expansions?.user[0]?.profile?.social_profiles[0]?.media}</Text>

                                            <Text mt="1" color="#637381" fontSize="16px" wordBreak="break-all">@{currentData?.expansions?.user[0]?.profile?.social_profiles[0]?.handle}</Text>

                                            {currentData?.expansions?.user[0]?.profile?.social_profiles[0]?.verified === true && <Text mt="1" color="#027A48" fontSize="10px"><i className="mdi mdi-check" style={{ marginRight: "5px"}}></i> VERIFIED</Text>}
                                        </Flex>

                                        <Flex ml="auto" align="center">
                                            <Flex cursor="pointer" bg="#FEF3F2" color="#B42318" borderRadius="8px" padding="10px 18px" justify="center" align="center" fontWeight="500" onClick={() => {
                                                setModal({
                                                    isOpen: true,
                                                    id: "DECLINE_KYC",
                                                    data: fid,
                                                    func: loadProfile
                                                })
                                            }}> 
                                                <Text mr="3">
                                                {declineIcon} 
                                                </Text>
                                                Decline
                                            </Flex>

                                            {
                                                currentData?.expansions?.user[0]?.profile?.social_profiles[0]?.verified === false && 
                                                <Flex ml="4" cursor="pointer" bg="#ECFDF3" color="#027A48" borderRadius="8px" padding="10px 18px" justify="center" align="center" fontWeight="500" onClick={() => {
                                                    setModal({
                                                        isOpen: true,
                                                        id: "APPROVE_KYC",
                                                        data: fid,
                                                        func: loadProfile
                                                    })
                                                }}> 
                                                    <Text mr="3">
                                                        {acceptIcon}
                                                    </Text>
                                                    Accept
                                                </Flex>
                                            }
                                        </Flex>
                                    </Flex>
                                </Flex>
                                }


                                <Flex direction="column" w="100%" boxShadow="0px 12px 24px -4px #919EAB1F, 0px 0px 2px 0px #919EAB33" padding="24px 24px" borderRadius="16px">
                                    <Flex align='center' mb="5">
                                        <Text mr="5">
                                            {kycIcon}
                                        </Text>
                                        <Text fontSize="18px" fontWeight="700" color="#212B36">KYC Documents</Text>
                                    </Flex>
                                    <Flex w="100%" direction="column">
                                    </Flex>
                                </Flex>
                            </Flex>
                        </Flex>
                        :
                        (oScreen === 1 && screen === 1) &&
                        <JobsTab fid={fid} />
                    }
                </Flex>


            </Flex>

        </Flex>
    )
}