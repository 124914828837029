import { Flex, Image, Text, Box, Spinner } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import Header from "../components/header";
import { AuthConnect } from "../util/request";
import Table from "../components/table";
import TransactionsModal from "../components/modals/transactionsModal";

export default function Transactions({ inner, innerId }) {
    const [screen, setScreen] = useState(0)
    const [data, setData] = useState()
    const [filter, setFilter] = useState("Filter by transaction type")
    const [loading, setLoading] = useState(false)
    const [loading2, setLoading2] = useState(false)
    const [loading3, setLoading3] = useState(false)
    const [isOpen, setIsOpen] = useState(false)
    const [currentData, setCurrentData] = useState("")

    async function loadData(direction, id, noLoad) {
        if(innerId) {
            noLoad === true ? setLoading(false) : direction === "prev" ? setLoading3(true) : direction === "next" ? setLoading2(true) : setLoading(true)
            const res = await AuthConnect("get", direction === "prev" ? "/customers/"+innerId+"/transactions?page[size]=20&sort[fields]=created_at:desc&page[cursor]="+id+"&page[forwards]="+false : direction === "next" ? "/customers/"+innerId+"/transactions?page[size]=20&sort[fields]=created_at:desc&page[cursor]="+id+"&page[forwards]="+true : "/customers/"+innerId+"/transactions?page[size]=20&sort[fields]=created_at:desc")
            setData(res)
            direction === "prev" ? setLoading3(false) : direction === "next" ? setLoading2(false) : setLoading(false)
        }
        else {
            noLoad === true ? setLoading(false) : direction === "prev" ? setLoading3(true) : direction === "next" ? setLoading2(true) : setLoading(true)
            const res = await AuthConnect("get", direction === "prev" ? "/customer-transactions?page[size]=20&sort[fields]=created_at:desc&page[cursor]="+id+"&page[forwards]="+false : direction === "next" ? "/customer-transactions?page[size]=20&sort[fields]=created_at:desc&page[cursor]="+id+"&page[forwards]="+true : "/customer-transactions?page[size]=20&sort[fields]=created_at:desc")
            setData(res)
            direction === "prev" ? setLoading3(false) : direction === "next" ? setLoading2(false) : setLoading(false)
        }
    }

    useEffect(() => {
        loadData()
    }, [])


    const tabs = ["All"]
    const tableHead = ["Transaction ID", "Transaction type", "Amount", "Currency", "Date created", "Status"]

    const ref1 = useRef("")

    async function updateScreen(item) {
        setIsOpen(true)
        setCurrentData(item)
    }

    return (
        <Flex w="100%" direction="column">
            {isOpen && <TransactionsModal setIsOpen={setIsOpen} data={currentData} loadData={loadData} />}
            
            {/******* TABLES PAGE ********* */}
            <Flex w="100%" direction="column" display={screen === 0 ? "flex" : "none"}>
                {!inner && <Header title="Transactions" sub="Manage users transactions here" />}

                <Table type="transactions" data={data} filter={filter} setFilter={setFilter} updateScreen={updateScreen} loading={loading} loading2={loading2} loading3={loading3} loadData={loadData} tabs={tabs} tableHead={tableHead} inpRef={ref1} setData={setData} setLoading={setLoading} />
            </Flex>




            {/******* OVERVIEW ********* */}
            <Flex w="100%" direction="column" display={screen === 1 ? "flex" : "none"}></Flex>
        </Flex>
    )
}