import { Flex, Image, Text } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { AuthConnect } from "../util/request";
import { useState, useEffect } from "react";
import Header from "../components/header";
import { ass1, ass2, ass3, crpto_arr, showDate, showTime } from "../components/svgs";
import { PieChart } from "react-minimal-pie-chart";
import flut from "../assets/images/flut.png"

export default function Metrics() {
    const [bal, setBal] = useState("")
    const [bal2, setBal2] = useState("")
    const [loading, setLoading] = useState(true)

    async function loadBalances() {
        setLoading(true)
        const res = await AuthConnect("get", "/customer-wallets/metrics")
        if(res) {
            setBal(res?.data)
        }
        setLoading(false)
    }

    async function loadBalances2() {
        const res = await AuthConnect("get", "/finops/flutterwave/balances")
        if(res) {
            setBal2(res?.data?.sort(({ledger_balance:a}, {ledger_balance:b}) => b-a)[0])
        }
    }

    useEffect(() => {
        loadBalances()
        loadBalances2()
    }, [])



    const zArr = (bal !== "") && [{title: "Total Asset Under Management", icon: 
        ass1, val: bal?.total_asset_aum[0]?.currency + " " + parseFloat(bal?.total_asset_aum[0]?.amount)?.toLocaleString()}, {title: "Total  FIAT AUM", icon: ass2, val: bal?.total_fiat_aum[0]?.currency + " " + parseFloat(bal?.total_fiat_aum[0]?.amount)?.toLocaleString()}, {title: "Total Crypto AUM", icon: ass3, val: bal?.total_crypto_aum[0]?.currency + " " + parseFloat(bal?.total_crypto_aum[0]?.amount)?.toLocaleString()}, {title: "Flutterwave Off-ramp Balance", icon: flut, val: bal2 === "" ? "NGN 0" : bal2?.currency + " " + parseFloat(bal2?.ledger_balance)?.toLocaleString()}]

    

        
    const defaultLabelStyle = {
        fontSize: '5px',
        fontFamily: 'Metro Sans',
        fontWeight: 500,
      };

    return (
        <Flex w="100%" direction="column"> 
            <Header title="Dashboard" sub="Metrics and overview" />
            {
                loading ?
                <Flex minH="170px" direction="column" py="50px">
                    <Flex w="50%" h="10px" borderRadius="10px" bg="#EDF2F7"></Flex>
                    <Flex w="30%" my="auto" h="10px" borderRadius="10px" bg="#EDF2F7"></Flex>
                    <Flex w="20%" h="10px" borderRadius="10px" bg="#EDF2F7"></Flex>
                </Flex>
                :
                bal !== "" && 
                <>
                    <Flex px="0px">
                        <Flex mt="8" w="100%" bg="#6421F208" border="1px solid #6421F2" borderRadius="5px" py="50px" px="30px" justify="space-between">
                            {
                                zArr?.map((item, index) => (
                                    <Flex w="auto" key={index} mr={index < 3 && "5"}>
                                        <Flex mr="2">
                                            {index === 3 ? <Image w="48px" h="48px" borderRadius="100%" src={item?.icon} /> : item?.icon}
                                        </Flex>
                                        <Flex direction="column">
                                            <Text color="#637381" fontSize="11px">{item.title}</Text>
                                            <Text mt="1" fontWeight="700" color="#071827" fontSize="18px">{item.val}</Text>
                                        </Flex>
                                    </Flex>
                                ))
                            }
                        </Flex>
                    </Flex>
                    <Text mt="12"></Text>
                    <Header title="Overview" sub="Total asset, fiat and crypto chart balances" />
                    <Text mt="5" mb="6" border="1px solid #EDF2F7"></Text>
                    <Flex w="100%" mt="8" align="center" px="10%">
                        <Flex w="380px">
                            <PieChart data={[
                                { title: 'Total Asset Under Management', value: parseInt(bal?.total_asset_aum[0]?.amount === "0" ? 10 : bal?.total_asset_aum[0]?.amount), color: '#B692F6', currency: bal?.total_asset_aum[0]?.currency },
                                { title: 'Total  FIAT AUM', value: parseInt(bal?.total_fiat_aum[0]?.amount === "0" ? 10 : bal?.total_fiat_aum[0]?.amount), color: '#F4EBFF', currency: bal?.total_fiat_aum[0]?.currency },
                                { title: 'Total Crypto AUM', value: parseInt(bal?.total_crypto_aum[0]?.amount === "0" ? 10 : bal?.total_crypto_aum[0]?.amount), color: '#53389E', currency: bal?.total_crypto_aum[0]?.currency },
                            ]}
                            label={({ dataEntry }) => dataEntry?.currency+" "+parseFloat(dataEntry.value === 10 ? 0 : dataEntry.value)?.toLocaleString()}
                            labelStyle={{
                                ...defaultLabelStyle,
                            }}
                            />
                        </Flex>
                        <Flex ml="10" direction="column">
                            <Flex align="center">
                                <Text mr='3' w="8px" h="8px" bg="#B692F6" borderRadius="100%"></Text>
                                <Text color="#667085" textTransform="capitalize" fontSize="14px">Total Asset Under Management</Text>
                            </Flex>

                            <Flex align="center" my="4">
                                <Text mr='3' w="8px" h="8px" bg="#F4EBFF" borderRadius="100%"></Text>
                                <Text color="#667085" textTransform="capitalize" fontSize="14px">Total FIAT AUM</Text>
                            </Flex>

                            <Flex align="center">
                                <Text mr='3' w="8px" h="8px" bg="#53389E" borderRadius="100%"></Text>
                                <Text color="#667085" textTransform="capitalize" fontSize="14px">Total Crypto AUM</Text>
                            </Flex>
                        </Flex>
                    </Flex>
                </>
            }

        </Flex>
    )
}