import { Flex, Image, Text, Box, Spinner } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import Header from "../components/header";
import { AuthConnect } from "../util/request";
import Table from "../components/table";
import BackButton from "../components/back";
import male from "../assets/images/male.png"
import female from "../assets/images/female.webp"
import paper from "../assets/images/Paper.svg"
import bman from "../assets/images/businessman.png"
import Card from "../components/card";
import { actionIcon, kycIcon } from "../components/svgs";
import Transactions from "./transactions";
import Referrals from "./referrals";
import Audit from "./audit";
import Status from "../components/status";
import Toast from "../components/notify";
import { showDate, showTime } from "../components/svgs";



export default function Users({ setModal }) {
    const [screen, setScreen] = useState(0)
    const [oScreen, setOScreen] = useState(0)
    const [data, setData] = useState()
    const [filter, setFilter] = useState("Filter")
    const [loading, setLoading] = useState(false)
    const [loading2, setLoading2] = useState(false)
    const [loading3, setLoading3] = useState(false)
    const [currentData, setCurrentData] = useState("")
    const [menu, setMenu] = useState(false)
    const [lastScroll, setLastScroll] = useState()
    const [isFraud, setIsFraud] = useState("")
    const [isDeactivated, setIsDeactivated] = useState("")

    async function loadData(direction, id) {
        direction === "prev" ? setLoading3(true) : direction === "next" ? setLoading2(true) : setLoading(true)
        const res = await AuthConnect("get", direction === "prev" ? "/customers?page[size]=20&expand[fields]=moderation_id&page[cursor]="+id+"&page[forwards]="+false : direction === "next" ? "/customers?page[size]=20&expand[fields]=moderation_id&page[cursor]="+id+"&page[forwards]="+true : "/customers?page[size]=20&expand[fields]=moderation_id")
        setData(res)
        direction === "prev" ? setLoading3(false) : direction === "next" ? setLoading2(false) : setLoading(false)
    }

    useEffect(() => {
        loadData()
    }, [])

    const tabs = ["All"]

    const tableHead = ["Name", "User ID", "Email address", "Phone number", "Last login date", "Status"]

    const ref1 = useRef("")

    async function updateScreen(item) {
        setLastScroll(window.scrollY)
        setScreen(1)
        setCurrentData(item)
    }
 

    //****************** OTHER SECTION ************************************ */
    function getFraud(id) {
        const isArr = data?.expansions?.moderation?.find(arr => arr.id === id);
        if(isArr?.is_fraudulent) {
            setIsFraud(currentData?.moderation_id)
        }
        else {
            setIsFraud("")
        }
    }
    function getDeactivated(id) {
        const isArr = data?.expansions?.moderation?.find(arr => arr.id === id);
        if(isArr?.is_deactivated) {
            setIsDeactivated(currentData?.moderation_id)
        }
        else {
            setIsDeactivated("")
        }
    }
    useEffect(() => {
        if(screen === 1) {
            window.scrollTo(0,0)
            getDeactivated(currentData?.moderation_id)
            getFraud(currentData?.moderation_id)
        }
        else if(screen === 0) {
            setIsFraud("")
            setIsDeactivated("")
        }
        setMenu(false)
    }, [screen])

    const oTabs = ["Details", "Uploaded documents", "Transactions", "Referrals", "Audit Trail"]

    const pData = [{title: "Full Name:", val: currentData?.profile?.fullname},{title: "Phone number:", val: currentData?.phone_number},{title: "Email address:", val: currentData?.email_address},{title: "Date of birth:", val: currentData?.profile?.birthday?.substr(0,10)},{title: "Gender:", val: currentData?.profile?.gender},{title: "Social Media Verified:", val: <Status status={currentData?.profile?.social_profiles[0]?.verified === true ? "Yes" : "No"} />},{title: "Social Media:", val: currentData?.profile?.social_profiles[0]?.media }, {title: "Social Media Handle:", val: currentData?.profile?.social_profiles[0]?.handle }]

    const lData = [{title: "Street", val: currentData?.profile?.address?.street},{title: "City", val: currentData?.profile?.address?.city},{title: "State", val: currentData?.profile?.address?.state},{title: "Country", val: currentData?.country}]


    


    return (
        <Flex w="100%" direction="column">
            
            {/******* TABLES PAGE ********* */}
            <Flex w="100%" direction="column" display={screen === 0 ? "flex" : "none"}>
                <Header title="Users" sub="Manage users here" />

                <Table type="users" data={data} filter={filter} setFilter={setFilter} updateScreen={updateScreen} loading={loading} loading2={loading2} loading3={loading3} loadData={loadData} tabs={tabs} tableHead={tableHead} inpRef={ref1} setData={setData} setLoading={setLoading} />
            </Flex>








            {/******* OVERVIEW ********* */}
            <Flex w="100%" direction="column" display={screen === 1 ? "flex" : "none"}>
                <BackButton setAction={setScreen} caption="User profile" lastScroll={lastScroll} setTabs={setOScreen} />

                {/*********** NAME SECTION ******************** */}
                <Flex w="100%" my="48px" justify="space-between" zIndex="18">
                    <Flex mr="12">
                        <Image bgColor="#6421F214" src={currentData?.profile?.gender === null ? bman : currentData?.profile?.gender === "Female" ? female : male} w="115px" h="115px" borderRadius="100%" />
                        <Text ml="6">
                            <Text fontSize="40px" fontWeight="700" color="#212B36">{currentData?.profile?.fullname}</Text>

                            <Text color="#637381" fontSize="16px">User ID: {currentData?.id} <Text as="span" ml="2" fontSize="20px" cursor="pointer" onClick={() => {
                                navigator.clipboard.writeText(currentData?.id)
                                Toast("User Id copied to clipboard!", "success")
                            }}><i className="mdi mdi-content-copy"></i></Text></Text>

                            <Text color="#212B36" fontSize="14px">Joined: {showDate(currentData?.created_at?.substr(0,10)) + " " + showTime(currentData?.created_at)}</Text>

                            {isDeactivated === currentData?.moderation_id && <Flex mt="2"><Status status="Deactivated" /></Flex>}

                            {isFraud === currentData?.moderation_id && <Flex mt="2"><Status status="Flagged as Fraud" /></Flex>}
                        </Text>
                    </Flex>

                    <Flex ml="auto" zIndex="18">
                        <Flex direction="column" position="relative">
                            <Flex cursor="pointer" bg="#6421F2" color="#fff" ml="auto" borderRadius="8px" padding="10px 18px" justify="center" align="center" fontWeight="500" onClick={() => menu ? setMenu(false) : setMenu(true)}>
                                Actions 
                                <Text ml="4">{actionIcon}</Text>
                            </Flex>

                            {
                                menu && 
                                <Flex backgroundImage={paper} backgroundSize="cover" backgroundRepeat="no-repeat" data-aos="fade-down" data-aos-duration="500" mt="12" right="-5" bgColor="#fff" position="absolute" w="200px" borderRadius="8px" boxShadow="-20px 20px 40px -4px #919EAB3D, 0px 0px 2px 0px #919EAB3D" direction="column">
                                    {
                                        [isDeactivated === currentData?.moderation_id ? "Reactivate User" : "Deactivate User", isFraud === currentData?.moderation_id ? "Unflag User" : "Flag as Fraud", "Delete User"].map((item, index) => (
                                            <Text key={index} cursor="pointer" padding="15px 12px" fontSize="14px" color="#637381" _hover={{ color: "#6421F2", fontWeight: "500" }} transition="200ms ease-inb-out" onClick={() => {
                                                if(item === "Deactivate User") {
                                                    setModal({
                                                        isOpen: true,
                                                        id: "DEACTIVATE",
                                                        data: currentData,
                                                        func: loadData,
                                                        refresh: setIsDeactivated,
                                                    })
                                                    setMenu(false)
                                                }
                                                else if(item === "Reactivate User") {
                                                    setModal({
                                                        isOpen: true,
                                                        id: "ACTIVATE",
                                                        data: currentData,
                                                        func: loadData,
                                                        refresh: setIsDeactivated,
                                                    })
                                                    setMenu(false)
                                                }
                                                else if(item === "Flag as Fraud") {
                                                    setModal({
                                                        isOpen: true,
                                                        id: "FRAUD",
                                                        data: currentData,
                                                        func: loadData,
                                                        refresh: setIsFraud,
                                                    })
                                                    setMenu(false)
                                                }
                                                else if(item === "Unflag User") {
                                                    setModal({
                                                        isOpen: true,
                                                        id: "UNFRAUD",
                                                        data: currentData,
                                                        func: loadData,
                                                        refresh: setIsFraud,
                                                    })
                                                    setMenu(false)
                                                }
                                                else if(index === 2) {
                                                    setModal({
                                                        isOpen: true,
                                                        id: "DELETE",
                                                        data: currentData?.id,
                                                        func: loadData,
                                                        refresh: setScreen,
                                                    })
                                                    setMenu(false)
                                                }
                                            }}>{item}</Text>
                                        ))
                                    }
                                </Flex>
                            }
                        </Flex>
                    </Flex>
                </Flex>

                {/*********** TAB SECTION ******************** */}
                <Flex w="100%" borderBottom="2px solid #919EAB14" px="20px" mb="40px"  position="sticky" top="80px" zIndex="15" bg="#fff">
                    {
                        oTabs.map((item, index) => (
                            <Flex mr="60px" py="16px" cursor="pointer" color={oScreen === index ? "#212B36" : "#667085"} borderBottom={oScreen === index ? "2px solid #212B36" : "2px solid transparent"} fontWeight={oScreen === index ? "700" : "500"} onClick={() => setOScreen(index)} transition="200ms ease-in-out" _hover={{ color: "#212B36", borderBottom: "2px solid #212B36", fontWeight: "700"}}>{item}</Flex>
                        ))
                    }
                </Flex>

                <Flex w="100%" direction="column">
                    {
                        oScreen === 0 ?
                        <Flex w="100%" justify="space-between" align="flex-start">
                            <Flex w="49%"><Card arr={pData} label="Personal Information" type="personal" /></Flex>        
                            <Flex w="48%"><Card arr={lData} label="Location" type="location" /></Flex>  
                        </Flex>
                        :
                        oScreen === 1 ?
                        screen === 1 && (
                            <Flex direction="column" w="50%" boxShadow="0px 12px 24px -4px #919EAB1F, 0px 0px 2px 0px #919EAB33" padding="24px 24px" borderRadius="16px">
                                <Flex align='center' mb="5">
                                    <Text mr="5">
                                        {kycIcon}
                                    </Text>
                                    <Text fontSize="18px" fontWeight="700" color="#212B36">KYC Documents</Text>
                                </Flex>
                                <Flex w="100%" direction="column">
                                </Flex>
                            </Flex>
                        )
                        :
                        oScreen === 2 ?
                        screen === 1 && (
                            <Flex mt="-6" w="100%" direction="column">
                                <Transactions innerId={currentData?.id} />
                            </Flex>
                        )
                        :
                        oScreen === 3 ?
                        screen === 1 && (
                            <Flex mt="-6" w="100%" direction="column">
                                <Referrals inner={true} innerId={currentData?.id} />
                            </Flex>
                        )
                        :
                        oScreen === 4 ?
                        screen === 1 && (
                            <Flex mt="-6" w="100%" direction="column">
                                <Audit inner={true} />
                            </Flex>
                        )
                        :
                        <></>
                    }
                </Flex>


            </Flex>
        </Flex>
    )
}