import Logo from "../assets/images/logo.svg"
import lock from "../assets/images/lock.svg"
import { Flex, Image, Text, Spinner } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import InputHolder from "../components/inputHolder";
import { useState, useRef } from "react";
import { SignIn } from "../util/request";
import Toast from "../components/notify";

export default function Forgot() {
    const [pass, setPass] = useState(false)
    const [loading, setLoading] = useState(false)
    const history = useHistory()
    const ref1 = useRef("")

    async function allowLogin() {
        var a = ref1.current.value
        if(a !== "") {
            setPass(true)
        }
        else {
            setPass(false)
        }
    }

    async function authUser(e) {
        e.preventDefault()
        if(pass) {
            setLoading(true)
            const res = await SignIn("post", "accounts/password-reset/initiation", {
                email_address: ref1.current.value,
            })
            if(res) {
                Toast(res?.message, "success")
                history.replace("/auth/recover")
            }
            setLoading(false)
        }
    }


    return (
        <Flex w="100%" direction="column">
            <Flex w="100%" padding="24px 24px" mb="70px">
                <Image src={Logo} w="88px" />
            </Flex>

            <Flex flex="1" justify="center" pb="50px">
                <Flex w="400px" direction="column" align="center">
                    <Image src={lock} w="96px" mb="12" />

                    <Text fontSize="32px" lineHeight="48px" fontWeight="700">Forgot your password?</Text>
                    <Text textAlign="center" fontSize="14px" fontWeight="500" color="#637381" mt="2">Please enter the email address associated with your account, and we'll email you a verification code to reset your password.</Text>
                    
                    <form onSubmit={(e) => authUser(e)} style={{ width: "100%" }}>
                        <Flex w="100%" direction="column" mt="10">
                            <InputHolder type="email" placeholder="Email address" inpRef={ref1} onInput={() => allowLogin()} required />
                        </Flex>

                        <Flex mt="1" w="100%">
                            <Flex as="button" transition="200ms ease-in-out" w="100%" padding="10px 18px" bg={pass ? "#6421F2" : "#E9D7FE"} color="#fff" justify="center" align="center" fontWeight="500" borderRadius="8px" boxShadow="0px 1px 2px 0px #1018280D" cursor={pass ? "pointer" : "not-allowed"}>{loading ? <Spinner color="#fff" emptyColor="lightgrey" /> : "Send request"}</Flex>
                        </Flex>
                    </form>

                    <Flex mt="8" align="center" justify="center">
                        <Flex cursor="pointer" color="#212B36" align="center" _hover={{ color: "#6421F2" }} transition="200ms ease-in-out" onClick={() => history.push("/auth/signin")}>   
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.99992 15.1667C4.04659 15.1667 0.833252 11.9534 0.833252 8.00004C0.833252 4.04671 4.04659 0.833374 7.99992 0.833374C11.9533 0.833374 15.1666 4.04671 15.1666 8.00004C15.1666 11.9534 11.9533 15.1667 7.99992 15.1667ZM7.99992 1.83337C4.59992 1.83337 1.83325 4.60004 1.83325 8.00004C1.83325 11.4 4.59992 14.1667 7.99992 14.1667C11.3999 14.1667 14.1666 11.4 14.1666 8.00004C14.1666 4.60004 11.3999 1.83337 7.99992 1.83337Z" fill="currentColor"/>
                            <path d="M8.83995 10.8533C8.71328 10.8533 8.58661 10.8066 8.48661 10.7066L6.13328 8.35332C5.93995 8.15998 5.93995 7.83998 6.13328 7.64665L8.48661 5.29332C8.67995 5.09998 8.99995 5.09998 9.19328 5.29332C9.38661 5.48665 9.38661 5.80665 9.19328 5.99998L7.19328 7.99998L9.19328 9.99998C9.38661 10.1933 9.38661 10.5133 9.19328 10.7066C9.09995 10.8066 8.97328 10.8533 8.83995 10.8533Z" fill="currentColor"/>
                            </svg>
                            <Text fontSize='14px' ml="2" fontWeight='600'>Return to Login in</Text>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    )
}