import { Flex, Image, Text, Spinner } from "@chakra-ui/react";
import Deactivate from "./deactivate";
import Success from "./success";
import Activate from "./activate";
import Fraud from "./fraud";
import Unfraud from "./unfraud";
import Delete from "./delete";
import ApproveKyc from "./approveKyc";
import DeclineKyc from "./declineKyc";
import CreateUser from "./createUser";
import LowBal from "./low";




export default function Modal({ modal, setModal }) {
    return (
        <Flex w="100%" direction="column" position="fixed" top="0" left="0" h="100%" justify="center" align="center" zIndex="1000">  
            <Flex zIndex="0" w="100%" bg="rgba(22, 28, 36, .8)" position="absolute" top="0" left="0" h="100%" cursor="pointer" onClick={() => setModal({
                isOpen: false,
            })}></Flex>

            <Flex data-aos="fade-up" data-aos-duration="500" zIndex="1" w="454px" bg="#fff" maxH="90vh" overflowY="scroll" className="customScroll" padding="40px 24px 24px 24px" boxShadow="-40px 40px 80px -8px #0000003D" borderRadius="16px" direction="column">
                {
                    modal?.id === "DEACTIVATE" ?
                    <Deactivate modal={modal} setModal={setModal} />
                    :
                    modal?.id === "SUCCESS" ?
                    <Success modal={modal} setModal={setModal} />
                    :
                    modal?.id === "DELETE" ?
                    <Delete modal={modal} setModal={setModal} />
                    :
                    modal?.id === "ACTIVATE" ?
                    <Activate modal={modal} setModal={setModal} />
                    :
                    modal?.id === "FRAUD" ?
                    <Fraud modal={modal} setModal={setModal} />
                    :
                    modal?.id === "UNFRAUD" ?
                    <Unfraud modal={modal} setModal={setModal} />
                    :
                    modal?.id === "APPROVE_KYC" ?
                    <ApproveKyc modal={modal} setModal={setModal} />
                    :
                    modal?.id === "DECLINE_KYC" ?
                    <DeclineKyc modal={modal} setModal={setModal} />
                    :
                    modal?.id === "CREATE_USER" ?
                    <CreateUser modal={modal} setModal={setModal} />
                    :
                    modal?.id === "LOW_BAL" ?
                    <LowBal modal={modal} setModal={setModal} />
                    :
                    <></>
                }
            </Flex>
        </Flex>
    )
}