import Logo from "../assets/images/logo.svg"
import lock from "../assets/images/lock.svg"
import { Flex, Image, Text, Spinner } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import InputHolder from "../components/inputHolder";
import { useState, useRef } from "react";
import { SignIn } from "../util/request";
import Toast from "../components/notify";

export default function ChangeForgot() {
    const [pass, setPass] = useState(false)
    const [loading, setLoading] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const history = useHistory()
    const ref1 = useRef("")
    const ref2 = useRef("")

    async function allowLogin() {
        var a = ref1.current.value
        var b = ref2.current.value
        if(a !== "" && b !== "") {
            setPass(true)
        }
        else {
            setPass(false)
        }
    }

    async function authUser(e) {
        e.preventDefault()
        if(pass) {
            setLoading(true)
            const res = await SignIn("post", "accounts/password-reset/completion", {
                verification_code: ref1.current.value, 
                new_password: ref2.current.value,
            })
            if(res) {
                Toast(res?.message, "success")
                setIsSuccess(true)
            }
            setLoading(false)
        }
    }


    return (
    <Flex w="100%" direction="column">
        <Flex w="100%" px="10%" py="60px" mb="50px">
            <Image src={Logo} w="157.88px" />
        </Flex>

        <Flex flex="1" justify="center" pb="50px" data-aos="fade-up">
            {
            !isSuccess ?
            <Flex w="400px" direction="column">
                <Text fontSize="32px" lineHeight="48px" fontWeight="700" textAlign="center">Reset your password</Text>
                <Text fontSize="14px" fontWeight="500" color="#637381" mt="2" textAlign="center">Enter the reset code sent to your email. Be sure to enter a password that you remember</Text>

                <form onSubmit={(e) => authUser(e)} style={{ width: "100%" }}>
                    <Flex w="100%" direction="column" mt="10">
                        <InputHolder type="password" placeholder="Verification Code" inpRef={ref1} onInput={() => allowLogin()} required />
                        <InputHolder type="password" placeholder="New Password" inpRef={ref2} onInput={() => allowLogin()} required />
                    </Flex>

                    <Flex mt="7" w="100%">
                        <Flex as="button" transition="200ms ease-in-out" w="100%" padding="10px 18px" bg={pass ? "#6421F2" : "#E9D7FE"} color="#fff" justify="center" align="center" fontWeight="500" borderRadius="8px" boxShadow="0px 1px 2px 0px #1018280D" cursor={pass ? "pointer" : "not-allowed"}>{loading ? <Spinner color="#fff" emptyColor="lightgrey" /> : "Update password"}</Flex>
                    </Flex>
                </form>
            </Flex>
            :
            <Flex w="400px" direction="column">
                <Text fontSize="32px" lineHeight="48px" fontWeight="700" textAlign="center">Password reset complete!</Text>
                <Text fontSize="14px" fontWeight="500" color="#637381" mt="2" textAlign="center">Your password was successfully reset. Login using your primary email address and your new password</Text>

                <Flex as="button" mt="8" transition="200ms ease-in-out" w="100%" padding="10px 18px" bg="#6421F2" color="#fff" justify="center" align="center" fontWeight="500" borderRadius="8px" boxShadow="0px 1px 2px 0px #1018280D" cursor="pointer" onClick={() => history.replace("/auth/signin")}>Continue to log in</Flex>
            </Flex>
            }
        </Flex>
    </Flex>
    )
}