import React from "react";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

function Index() {
  const auth = sessionStorage.getItem("ZineAuth");
  const history = useHistory();

  useEffect(() => {
    if (auth !== null) {
      history.replace("/dashboard");
    } else {
      history.replace("/auth/signin");
    }
  }, []);
  return null;
}

export default Index;
