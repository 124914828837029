import { Flex, Image, Text, Box, Spinner } from "@chakra-ui/react";
import InputHolder from "./inputHolder";
import Status from "./status";
import { crypt_arr } from "./crpt_arr";
import { showTime, showDate, crpto_arr } from "./svgs";
import crypt from "../assets/images/crypt.svg"
import { AuthConnect } from "../util/request";
import { useRef, useState, useEffect } from "react";
import Toast from "./notify";


export default function Table({ type, data, filter, setFilter, updateScreen, loading, loading2, loading3, loadData, tabs, tableHead, inpRef, setData, setLoading }) {
    const [isSearch, setIsSearch] = useState(false)

    function getFullName(abbreviation) {
        const currency = crypt_arr.find(crypto => crypto.abbreviation === abbreviation);
        return currency ? currency.fullname : abbreviation;
    }

    function getExpandName(id, type) {
        const isArr = data?.expansions?.[type]?.find(arr => arr.id === id);
        return isArr ? isArr?.profile?.fullname === "null null" ? "___" : isArr?.profile?.fullname : "___";
    }


    function checkAll(e) {
        var checkAll = e.target.checked;
        var checkboxes = document.querySelectorAll('.checkboxes');
        for (var i = 0; i < checkboxes.length; i++) {
            if (checkboxes[i].type == "checkbox") {
                if (!checkboxes[i].checked && checkAll) {
                    checkboxes[i].checked = true;
                } else if(!checkAll) {
                    checkboxes[i].checked = false;
                }
            }
        }
    }

    function userActive(isoDate) { const targetDate = new Date(isoDate);
        const currentDate = new Date();
        const yearDiff = targetDate.getUTCFullYear() - currentDate.getUTCFullYear();
        const monthDiff = targetDate.getUTCMonth() - currentDate.getUTCMonth();
        const totalMonthDiff = yearDiff * 12 + monthDiff;
        return Math.abs(parseInt(totalMonthDiff)) > 0;
    }

    function getCryptoImage(key) {
        return crpto_arr[key] || crypt;
    }

    const ref1 = useRef("")

    async function searchFunc(dir) {
        var a = ref1.current.value
        const rs = type === "transactions" ? "Transaction" : type === "kyc" ? "Verification" : type === "jobs" ? "VerificationTask" : type === "wallets" ? "Wallet" : type === "assets" ? "Holding" : type === "admin" ? "Administrator" : "User"

        if(a !== "") {
            setLoading(true)
            const res = await AuthConnect("get", "/search/"+(type === "admin" ? "a": "c")+"?resource="+rs+"&search="+a+"&page[size]=20&&expand[fields]=owner_id")
            const ua = {data: res?.data[0]?.resources?.users}
            const ta = {data: res?.data[0]?.resources?.transactions}
            const ka = {data: res?.data[0]?.resources?.verifications}
            const aa = {data: res?.data[0]?.resources?.holdings}
            const wa = {data: res?.data[0]?.resources?.wallets}
            const ja = {data: res?.data[0]?.resources?.verification_tasks}
            const da = {data: res?.data[0]?.resources?.administrators}
            setData(type === "transactions" ? ta : type === "kyc" ? ka :  type === "jobs" ? ja : type === "wallets" ? wa : type === "assets" ? aa : type === "admin" ? da : ua)
            setLoading(false)
        }
        else {
            Toast("Enter the search field", "error")
        }
    }

    const finalArr = type === "referrals" ? data?.expansions?.user : data?.data


    return (
        <Flex mt="6" w="100%" direction="column" bg="#fff" boxShadow="0px 12px 24px -4px #919EAB1F, 0px 0px 2px 0px #919EAB33" borderRadius="8px">
            {/******************************** TABS **************** */}
            <Flex w="100%" px='20px' borderBottom="2px solid #919EAB14">
                {
                    tabs.map((item, index) => (
                        <Flex cursor="pointer" py="16px" key={index} color="#475467" fontWeight="500" fontSize="14px" align="items" borderBottom="2px solid #919EAB">
                            {item}
                            {data?.meta?.total ? <Flex borderRadius="6px" bg="#EAECF0" w="34px" h="24px" justify='center' align='center' padding="2px, 10px, 2px, 10px" ml="3" color="#344054" fontSize="12px">{data?.meta?.total}</Flex> : <></>}
                        </Flex>
                    ))
                }
            </Flex>



            
            {/******************************** SEARCH **************** */}
            <Flex w="100%" px="20px" py="20px" align="center">
                <Flex mr="4">
                    <Flex className="inpEffect" padding="10px 14px" border="1px solid #D0D5DD" boxShadow="0px 1px 2px 0px #1018280D" color="#667085" fontSize="16px" borderRadius="8px" cursor="pointer" align="center" minW="200px">
                        {filter}
                        <Text ml="auto"><svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1.5L6 6.5L11 1.5" stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/></svg></Text>
                    </Flex>
                </Flex>

                <Flex flex="1" pr="5%"><InputHolder type="search" placeholder={type === "transactions" ? "Search by Date or transaction ID" : type === "referrals" ? "Search by name or user ID" : type === "kyc" ? "Search by user Id" : type === "jobs" ? "Search by id" : type === "assets" ? "Search by crypto abbreviation e.g USDT" : type === "wallets" ? "Search by wallet Id" : "Search by name, user ID, phone or email"} noMb={true} inpRef={ref1} loadData={loadData} searchFunc={searchFunc} isSearch={isSearch} setIsSearch={setIsSearch} /></Flex>
            </Flex>




            {/******************************** TABLE **************** */}
            <Box w="100%">
                <Flex minW="100%" direction="column">
                    <Flex w='100%'  bg="#F9FAFB" borderBottom="1px solid #EAECF0" align="center">
                        <Flex align="center" w="fit-content" padding="12px 0px 12px 24px"><input type="checkbox" onChange={checkAll} /></Flex>
                        {
                            tableHead.map((item, index) => (
                                <Flex 
                                w={
                                    (item === "Status" || item === "Activated") ? "165px" : (type === "kyc" || type === "referrals" || type === "jobs") ? "25%" : (type === "assets" || type === "enquiry") ? "16.6%" : type === "wallet" ? "14.2857143%" : "20%"
                                } 
                                padding="12px 24px" fontWeight="500" fontSize="12px" color="#667085" key={index}>{item}</Flex>
                            ))
                        }
                    </Flex>
                    {
                        loading ?
                        <Flex w="100%" justify="center" py="100px">
                            <Spinner color="#6421F2" emptyColor="lightgrey" w="50px" h="50px" />
                        </Flex>
                        :
                        Array.isArray(finalArr) ?
                        finalArr?.map((item, index) => (
                            <Flex w='100%' bg="#fff" borderBottom="1px solid #EAECF0" key={index} fontSize="13px" color="#212B36" fontWeight="500" align="flex-start" cursor="pointer" _hover={{ bg: "#F9FAFB" }} transition="200ms ease-in-out" onClick={() => updateScreen(item)}>

                                <Flex align="flex-end" w="fit-content" padding="18px 0px 16px 24px"><input type="checkbox" className="checkboxes" /></Flex>

                                {
                                    type === "users" ?
                                    <>
                                        <Flex w="20%" padding="16px 24px" flexWrap="wrap" wordBreak="break-all">{item?.profile?.fullname === "null null" ? "___" : item?.profile?.fullname ?? "___"}</Flex>
                                        <Flex w="20%" padding="16px 24px" flexWrap="wrap" wordBreak="break-all">{item?.id?.substr(0,13)+"..."}</Flex>
                                        <Flex w="20%" padding="16px 24px" flexWrap="wrap" wordBreak="break-all">{item?.email_address}</Flex>
                                        <Flex w="20%" padding="16px 24px" flexWrap="wrap" wordBreak="break-all">{item?.phone_number}</Flex>
                                        <Flex w="20%" padding="16px 24px" flexWrap="wrap" wordBreak="break-all">{showDate(item?.last_active_at?.substr(0,10)) + " " + showTime(item?.last_active_at)}</Flex>
                                        <Flex padding="16px 24px" w="165px">
                                            <Status status={userActive(item?.last_active_at) ? "Inactive" : "Active"} />
                                        </Flex>
                                    </>
                                    :
                                    type === "transactions" ?
                                    <>
                                        <Flex w="20%" padding="16px 24px" flexWrap="wrap" wordBreak="break-all">{item?.id?.substr(0,13)+"..."}</Flex>
                                        <Flex w="20%" padding="16px 24px" flexWrap="wrap" wordBreak="break-all">{item?.type[0]?.toUpperCase() + item?.type?.slice(1).toLowerCase()}</Flex>
                                        <Flex w="20%" padding="16px 24px" flexWrap="wrap" wordBreak="break-all">{parseFloat(item?.amount)?.toLocaleString()}</Flex>
                                        <Flex w="20%" padding="16px 24px" flexWrap="wrap" wordBreak="break-all">{item?.currency}</Flex>
                                        <Flex w="20%" padding="16px 24px" flexWrap="wrap" wordBreak="break-all">{showDate(item?.created_at?.substr(0,10)) + " " + showTime(item?.created_at)}</Flex>
                                        <Flex padding="16px 24px" w="165px">
                                            <Status status={item?.status} />
                                        </Flex>
                                    </>
                                    :
                                    type === "kyc" ?
                                    <>
                                        <Flex w="25%" padding="16px 24px" flexWrap="wrap" wordBreak="break-all">{getExpandName(item?.user_id, "user")}</Flex>
                                        <Flex w="25%" padding="16px 24px" flexWrap="wrap" wordBreak="break-all">{item?.user_id?.substr(0,13)+"..."}</Flex>
                                        <Flex w="25%" padding="16px 24px" flexWrap="wrap" wordBreak="break-all">{item?.level}</Flex>
                                        <Flex w="25%" padding="16px 24px" flexWrap="wrap" wordBreak="break-all">{showDate(item?.created_at?.substr(0,10)) + " " + showTime(item?.created_at)}</Flex>
                                        <Flex padding="16px 24px" w="165px">
                                            <Status status={item?.completed === true ? "Completed" : "Incomplete"} />
                                        </Flex>
                                    </>
                                    :
                                    type === "jobs" ?
                                    <>
                                        <Flex w="25%" padding="16px 24px" flexWrap="wrap" wordBreak="break-all">{item?.document_type}</Flex>
                                        <Flex w="25%" padding="16px 24px" flexWrap="wrap" wordBreak="break-all">{item?.id?.substr(0,13)+"..."}</Flex>
                                        <Flex w="25%" padding="16px 24px" flexWrap="wrap" wordBreak="break-all">{item?.in_review ? "Yes" : "No"}</Flex>
                                        <Flex w="25%" padding="16px 24px" flexWrap="wrap" wordBreak="break-all">{showDate(item?.created_at?.substr(0,10)) + " " + showTime(item?.created_at)}</Flex>
                                        <Flex padding="16px 24px" w="165px">
                                            <Status status={item?.status} />
                                        </Flex>
                                    </>
                                    :
                                    type === "referrals" ?
                                    <>
                                        <Flex w="25%" padding="16px 24px" flexWrap="wrap" wordBreak="break-all">{item?.profile?.fullname}</Flex>
                                        <Flex w="25%" padding="16px 24px" flexWrap="wrap" wordBreak="break-all">{item?.id?.substr(0,13)+"..."}</Flex>
                                        <Flex w="25%" padding="16px 24px" flexWrap="wrap" wordBreak="break-all">{item?.points ?? "0"}</Flex>
                                        <Flex w="25%" padding="16px 24px" flexWrap="wrap" wordBreak="break-all"> {showDate(item?.created_at?.substr(0,10)) + " " + showTime(item?.created_at)}</Flex>
                                       
                                    </>
                                    :
                                    type === "admin" ?
                                    <>
                                        <Flex w="20%" padding="16px 24px" flexWrap="wrap" wordBreak="break-all" textTransform="capitalize">{item?.first_name + " " + item?.last_name}</Flex>
                                        <Flex w="20%" padding="16px 24px" flexWrap="wrap" wordBreak="break-all">{item?.email_address}</Flex>
                                        <Flex w="20%" padding="16px 24px" flexWrap="wrap" wordBreak="break-all">{item?.id?.substr(0,13)+"..."}</Flex>
                                        <Flex w="20%" padding="16px 24px" flexWrap="wrap" wordBreak="break-all" textTransform="capitalize">{item?.role}</Flex>
                                        <Flex w="20%" padding="16px 24px" flexWrap="wrap" wordBreak="break-all">{showDate(item?.created_at?.substr(0,10)) + " " + showTime(item?.created_at)}</Flex>
                                        <Flex padding="16px 24px" w="165px">
                                            <Status status={item?.is_activated ? "Yes" : "No"} />
                                        </Flex>
                                    </>
                                    :
                                    type === "enquiry" ?
                                    <>
                                        <Flex w="16.6%" padding="16px 24px" flexWrap="wrap" wordBreak="break-all" textTransform="capitalize">{item?.first_name}</Flex>
                                        <Flex w="16.6%" padding="16px 24px" flexWrap="wrap" wordBreak="break-all" textTransform="capitalize">{item?.last_name}</Flex>
                                        <Flex w="16.6%" padding="16px 24px" flexWrap="wrap" wordBreak="break-all">{item?.email_address}</Flex>
                                        <Flex w="16.6%" padding="16px 24px" flexWrap="wrap" wordBreak="break-all">{item?.phone_number}</Flex>
                                        <Flex w="16.6%" padding="16px 24px" flexWrap="wrap" wordBreak="break-all">{item?.message?.length < 15 ? item?.message : item?.message?.substr(0,11)+"..."}</Flex>
                                        <Flex w="16.6%" padding="16px 24px" flexWrap="wrap" wordBreak="break-all">{showDate(item?.created_at?.substr(0,10)) + " " + showTime(item?.created_at)}</Flex>
                                    </>
                                    :
                                    type === "assets" ?
                                    <>
                                        <Flex w="16.6%" padding="16px 24px" wordBreak="break-all" align="center">
                                            <Image src={getCryptoImage(item?.currency)} w="26px" h="26px" mr="2" /> <Flex wordBreak="break-all">{getFullName(item?.currency)}</Flex>
                                        </Flex>
                                        <Flex w="16.6%" padding="16px 24px" flexWrap="wrap" wordBreak="break-all">{item?.currency}</Flex>
                                        <Flex w="16.6%" padding="16px 24px" flexWrap="wrap" wordBreak="break-all">{item?.currency_type[0]?.toUpperCase() + item?.currency_type?.slice(1).toLowerCase()}</Flex>
                                        <Flex w="16.6%" padding="16px 24px" flexWrap="wrap" wordBreak="break-all">{parseFloat(item?.balance)?.toLocaleString() + " " + item?.currency}</Flex>
                                        <Flex w="16.6%" padding="16px 24px" flexWrap="wrap" wordBreak="break-all">{parseFloat(item?.funding_balance)?.toLocaleString() + " " + item?.currency}</Flex>
                                        <Flex w="16.6%" padding="16px 24px" flexWrap="wrap" wordBreak="break-all">{parseFloat(item?.spot_balance)?.toLocaleString() + " " + item?.currency}</Flex>
                                    </>
                                    :
                                    type === "wallets" ?
                                    <>
                                        <Flex w="14.2857143%" padding="16px 24px" flexWrap="wrap" wordBreak="break-all">{item?.type}</Flex>
                                        <Flex w="14.2857143%" padding="16px 24px" flexWrap="wrap" wordBreak="break-all">{getExpandName(item?.owner_id, "owner")}</Flex>
                                        <Flex w="14.2857143%" padding="16px 24px" flexWrap="wrap" wordBreak="break-all">{item?.balance[0]?.currency}</Flex>
                                        <Flex w="14.2857143%" padding="16px 24px" flexWrap="wrap" wordBreak="break-all">{parseFloat(item?.balance[0]?.amount)?.toLocaleString()}</Flex>
                                        <Flex w="14.2857143%" padding="16px 24px" flexWrap="wrap" wordBreak="break-all">{item?.id?.substr(0,13)+"..."}</Flex>
                                        <Flex w="14.2857143%" padding="16px 24px" flexWrap="wrap" wordBreak="break-all">{showDate(item?.created_at?.substr(0,10)) + " " + showTime(item?.created_at)}</Flex>
                                        <Flex w="14.2857143%" padding="16px 24px" flexWrap="wrap" wordBreak="break-all">
                                            <Flex padding="8px 14px" border="1px solid #6421F2" color="#6421F2" fontSize="14px" fontWeight="600" bg="#fff" cursor="pointer" borderRadius="8px">View</Flex>
                                        </Flex>
                                    </>
                                    :
                                    type === "finopsFlutterwave1" ?
                                    <>
                                        <Flex w="20%" padding="16px 24px" flexWrap="wrap" wordBreak="break-all">{parseFloat(item?.charged_amount)?.toLocaleString()}</Flex>
                                        <Flex w="20%" padding="16px 24px" flexWrap="wrap" wordBreak="break-all">{parseFloat(item?.flw_fee)?.toLocaleString()}</Flex>
                                        <Flex w="20%" padding="16px 24px" flexWrap="wrap" wordBreak="break-all">{item?.payment_channel?.type}</Flex>
                                        <Flex w="20%" padding="16px 24px" flexWrap="wrap" wordBreak="break-all">{parseFloat(item?.settled_amount)?.toLocaleString()}</Flex>
                                        <Flex w="20%" padding="16px 24px" flexWrap="wrap" wordBreak="break-all">{showDate(item?.created_at?.substr(0,10)) + " " + showTime(item?.created_at)}</Flex>
                                        <Flex padding="16px 24px" w="165px">
                                            <Status status={item?.status} />
                                        </Flex>
                                    </>
                                    :
                                    type === "finopsQuidax1" ?
                                    <>
                                        <Flex w="20%" padding="16px 24px" flexWrap="wrap" wordBreak="break-all">{parseFloat(item?.amount)?.toLocaleString()}</Flex>
                                        <Flex w="20%" padding="16px 24px" flexWrap="wrap" wordBreak="break-all">{item?.currency}</Flex>
                                        <Flex w="20%" padding="16px 24px" flexWrap="wrap" wordBreak="break-all">{item?.destination_user?.email}</Flex>
                                        <Flex w="20%" padding="16px 24px" flexWrap="wrap" wordBreak="break-all">{item?.destination_wallet?.deposit_address}</Flex>
                                        <Flex w="20%" padding="16px 24px" flexWrap="wrap" wordBreak="break-all">{showDate(item?.done_at?.substr(0,10)) + " " + showTime(item?.done_at)}</Flex>
                                        <Flex padding="16px 24px" w="165px">
                                            <Status status={item?.status} />
                                        </Flex>
                                    </>
                                    :
                                    type === "finopsFlutterwave2" ?
                                    <>
                                        <Flex w="20%" padding="16px 24px" flexWrap="wrap" wordBreak="break-all">{parseFloat(item?.amount)?.toLocaleString()+" "+item?.currency}</Flex>
                                        <Flex w="20%" padding="16px 24px" flexWrap="wrap" wordBreak="break-all">{item?.account_name}</Flex>
                                        <Flex w="20%" padding="16px 24px" flexWrap="wrap" wordBreak="break-all">{item?.bank_name}</Flex>
                                        <Flex w="20%" padding="16px 24px" flexWrap="wrap" wordBreak="break-all">{parseFloat(item?.fee)?.toLocaleString()}</Flex>
                                        <Flex w="20%" padding="16px 24px" flexWrap="wrap" wordBreak="break-all">{showDate(item?.created_at?.substr(0,10)) + " " + showTime(item?.created_at)}</Flex>
                                        <Flex padding="16px 24px" w="165px">
                                            <Status status={item?.status} />
                                        </Flex>
                                    </>
                                    :
                                    <></>
                                }
                            </Flex>
                        ))
                        : 
                        <Flex w="100%" justify="center" py="100px" color="#637381" fontWeight="500">No Data</Flex>
                    }
                </Flex>
            </Box>

            {
                (data?.meta?.has_next || data?.meta?.has_prev) &&
                <Flex w="100%" justify="space-between" padding="12px 24px 16px 24px">
                    {
                        data?.meta?.has_prev &&
                        <Flex border="1px solid #D0D5DD" boxShadow="0px 1px 2px 0px #1018280D" borderRadius="8px" padding="8px 14px" align="center" fontSize="14px" fontWeight="500" color="#344054" onClick={() => loadData("prev", data?.meta?.before)} cursor="pointer">
                        {
                            loading3 ?
                            <Spinner color="#6421F2" emptyColor="lightgrey" />
                            :
                            <>   
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M15.8334 10.0001H4.16675M4.16675 10.0001L10.0001 15.8334M4.16675 10.0001L10.0001 4.16675" stroke="#344054" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/></svg>
                                <Text ml="3">Previous</Text>
                            </>
                        }
                        </Flex>
                    }

                    {
                        data?.meta?.has_next &&
                        <Flex ml="auto" border="1px solid #D0D5DD" boxShadow="0px 1px 2px 0px #1018280D" borderRadius="8px" padding="8px 14px" align="center" fontSize="14px" fontWeight="500" color="#344054" onClick={() => loadData("next", data?.meta?.after)} cursor="pointer">
                            {
                                loading2 ?
                                <Spinner color="#6421F2" emptyColor="lightgrey" />
                                :
                                <>
                                    <Text mr="3">Next</Text>
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.16663 7.00008H12.8333M12.8333 7.00008L6.99996 1.16675M12.8333 7.00008L6.99996 12.8334" stroke="#344054" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/></svg>
                                </>
                            }
                        </Flex>
                    }
                </Flex>
            }
        </Flex>
    )
}